<div class="" [ngClass]="scrollPage?'cvSticky':'cvheader'">
    <!-- <div class="imgBox text-center">
        <img class="cvLogo" [src]="icons.cvLogo">
    </div> -->
    <div class="headingBox text-center " [ngClass]="scrollPage?'justifyRight d-flex':'text-center'">
        <!-- <div> -->
            <p class=" fontKaushan mb-0" [ngClass]="scrollPage?'cvHeadingScroll':'cvHeading'">
                Chandni Visual's
            </p>
        <!-- </div> -->
        <div class="alignCenter" *ngIf="!mobFlag">
        <div *ngIf="scrollPage" class="d-inline-flex">
            <button (click)="headerClicked('about')" class="btn btn-link">home</button>
            <button (click)="headerClicked('Wedding')" class="btn btn-link">wedding</button>
            <button (click)="headerClicked('Personal')" class="btn btn-link">Personal portfolio</button>
            <button (click)="headerClicked('Wild')" class="btn btn-link">Wildlife/Nature</button>
            <button (click)="headerClicked('Event')" class="btn btn-link">Event</button>
            <button (click)="headerClicked('Video')" class="btn btn-link">Video</button>
            <button (click)="headerClicked('bookshoot')" class="btn btn-link">Book your Shoot</button>
            <button (click)="headerClicked('locate')" class="btn btn-link">Locate Us</button>
        </div>
    </div>
    </div>

    <!-- <div class="text-center">
        <h3 onmouseover="this.stop();" onmouseout="this.start();" class="subHead">
            We Capture, Moments of Love, Rhythm, Joy, Emotions. So Feel Your Special Moments With,"Chandni
            Visuals",Wedding Filmer.
    
        </h3>
    </div> -->
    <!-- <div class="">
        <app-login></app-login>
    </div> -->
</div>