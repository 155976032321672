<div class="mainImageContainer">
    <div class="text-center bkheading m-3">{{clickedHead}}</div>
    <div class=" m-0  ">
        <div class="d-flex justifyS">
            <div *ngFor="let fileUpload of fileUploads let index =index;
            let isOdd=odd;
            let isEven=even" [class.imageMainBoxCard]="isOdd" [class.imageMainBoxL]="isEven">

                <div class="imageshown" [ngStyle]="{'background-image': 'url(' + fileUpload.url + ')'}">
                </div>
            </div>
        </div>
    </div>
</div>