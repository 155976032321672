import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './components/admin/admin.component';
import { HomeComponentComponent } from './components/home-component/home-component.component';

const routes: Routes = [
  {path:'',component:HomeComponentComponent},
  {path:'jaibholebabajiki/admin',component:AdminComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
 