import { Component, Input, OnInit } from '@angular/core';
import { DataInteractionService } from 'src/app/services/data-interaction.service';

@Component({
  selector: 'app-card-images',
  templateUrl: './card-images.component.html',
  styleUrls: ['./card-images.component.scss']
})
export class CardImagesComponent implements OnInit {
  @Input () cardData
  headingFrame: any;
  fileUploads: { key: string; name: string; url: string; file: File; }[];
  clickedHead: any;
  constructor(private dataService:DataInteractionService) { }

  ngOnInit(): void {
    console.log("cardData",this.cardData);
     this.getHeading(this.cardData);
    this.getImagesData(this.cardData);
  }
  getHeading(value:string){
    value === 'Wedding'?
    this.clickedHead = 'Wedding Gallery':
    value === 'Personal'?
    this.clickedHead = 'Personal Portfolio Gallery':
    value === 'Wild'?
    this.clickedHead = "Wildlife & Nature":
    value === 'Event'?
    this.clickedHead='Event Gallery':'';

  }
  getImagesData(data){
    data === 'Wedding' ?
    this.fileUploads = this.dataService.imagesWedGallery:
    data === 'Personal' ?
      this.fileUploads = this.dataService.imagesPerGallery:
      data === 'Wild' ?
        this.fileUploads = this.dataService.imagesWildGallery:
        data === 'Event' ?
          this.fileUploads = this.dataService.imagesEventGallery:'';
          console.log("cardfile",this.fileUploads);
  }
  
}
