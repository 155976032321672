<div class="d-flex " style="justify-content: space-between;">

    <button class="goUp" (click)="onScrolltop()">
        <img src="../../../assets/images/caret-square-up.svg">
    </button>



    <button class="connectCall" (click)="call()" data-toggle="modal" data-target="#call">
        <img class="" src="../../../../assets/images/phone-square.svg">
    </button>
    <div class="modal fade m-0 p-0 " role="dialog" id="call">
        <div class="modal-dialog  modalBox mx-auto m-0 p-0" role="document">
            <div class="modal-content bckGColor text-light">
                <div class="modal-header p-0 d-flex">
                    <div class="cvlogo mx-1"></div>
                    <h3 class="mx-auto cvHeading mt-2"> {{cmsDataPH.companyName}}</h3>
                    <div  class="mx-0 fontOrangered close" data-dismiss="modal" aria-label="Close">&times;</div>
                </div>

                <div class="modal-body">
                    <div class="m-0 p-0" *ngIf="!submitflag">
                        <form class="create-form" [formGroup]="exampleForm" (ngSubmit)="onSubmit(exampleForm.value)">

                            <div class="form-group">
                                <input placeholder="{{cmsDataPH.fullNameHeading}}" class="form-control"
                                    formControlName="fullName">

                            </div>
                            <div class="form-group">
                                <input type="text" min="0" max="100" placeholder="{{cmsDataPH.mobileNUmberHeading}}"
                                    class="form-control" formControlName="mobileno">
                            </div>
                            <div class="form-group">
                                <input type="email" min="0" max="100" placeholder="{{cmsDataPH.emailHeading}}"
                                    class="form-control" formControlName="email">
                            </div>
                            <div class="form-group">
                                <textarea  type="text" min="0" max="100" placeholder="{{cmsDataPH.commentsHeading}}" class="form-control"
                                formControlName="feedback"></textarea>
                            </div>
                        </form>
                        <div class="row submit-button-container">
                            <div class="col-md-4 mx-auto">
                                <button class="submit-button" class="btn btnColor btn-block" type="submit"
                                    (click)="onSubmit(exampleForm.value)"
                                    [disabled]="!exampleForm.valid">{{cmsDataPH.callBtnText}}</button>
                            </div>
                        </div>
                    </div>

                    <div class="m-0 p-0" *ngIf="submitflag">
                        <p class="m-0 p-3 display-5">{{cmsDataPH.thanksPostSubmit}}</p>
                        <p class="m-0 p-3 display-6">{{cmsDataPH.messagePostSubmit}}</p>
                    </div>

                    <div class="mt-2 text-danger">
                        {{cmsDataPH.callHeading}} @ <a class="text-danger" href="tel:+919250925044"> +91-9250 9250 44</a>
                    </div>
                    <div class="row m-0 p-0 mt-3">
                        <div class="col-1 p-0 col-lg-1 col-md-1 col-sm-1 col-xl-1 col-xs-1"></div>
                        <div class="col-2 p-0 col-lg-2 col-md-2 col-sm-2 col-xl-2 col-xs-2">
                            <a href="https://www.facebook.com/Chandni-Visuals-106347514755299"><img class="imgDiv"
                                    src="/assets/images/facebook-square.svg"></a>
                        </div>
                        <div class="col-2 p-0 col-lg-2 col-md-2 col-sm-2 col-xl-2 col-xs-2">
                            <a href="https://goo.gl/maps/geXoTC9nAshEuc5VA"><img class="imgDiv"
                                src="/assets/images/google-maps.svg"></a>
                        </div>
                        <div class="col-2 p-0 col-lg-2 col-md-2 col-sm-2 col-xl-2 col-xs-2">
                            <a href="https://www.youtube.com/channel/UC1-eaR9E2PaIQV2b6YRMB0w"><img class="imgDiv"
                                src="/assets/images/youtube-square.svg"></a>
                        </div>
                        <div class="col-2 p-0 col-lg-2 col-md-2 col-sm-2 col-xl-2 col-xs-2">
                            <a href="https://www.instagram.com/chandnivisuals/"><img class="imgDiv"
                                src="/assets/images/instagram-square.svg"></a>
                        </div>
                        <div class="col-2 p-0 col-lg-2 col-md-2 col-sm-2 col-xl-2 col-xs-2">
                            <a href="https://wa.me/+919250925088"><img class="imgDiv"
                                src="/assets/images/whatsapp-square.svg"></a>
                        </div>
                        <div class="col-1 p-0 col-lg-1 col-md-1 col-sm-1 col-xl-1 col-xs-1"></div>                     
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>