export class cvIcons{
    public static getImage():any{
        const icons = {
            cvLogo:'assets/images/cvlogo.png',
            cvHeading:'assets/images/Visual.png',
            cvNavBanner:'assets/images/navigationBanner.png',
            weddingLogo:'assets/images/videoIcon.svg',
            portfolioLogo:'assets/images/portrait.svg',
            wildLogo:'assets/images/wildlife&nature.svg',
            eventLogo:'assets/images/hand-point-down.svg',
            bookingLogo:'assets/images/bookingForm.svg',
            locateLogo:'assets/images/locateUs.svg',
            videoLogo:'assets/images/videoGallery.svg'
        };
        return icons;
    }
}