<button class="btn btn-success registerBtn"  data-toggle="modal" data-target="#register">
    Register
</button>
<div class="modal fade m-0 p-0 " role="dialog" id="register">
    <div class="modal-dialog  modalBox mx-auto m-0 p-0" role="document">
        <div class="modal-content bckGColor text-light">
            <div class="loginMainbOX">
                <pre class="greetings">
                    Hey,
                    Login Now
                    If  you are new / Create New
                </pre>
                <div class="formBox">
                    <form [formGroup]='loginDetails' (ngSubmit)='loginFormSubmit(loginDetails.value)'>
                        <div>
                            <label class="custom-form-label">Username/Email Id</label>
                            <input type="text" formControlName="userName">
                        </div>
                        <div>
                            <label class="custom-form-label">Password</label>
                            <input type="password" formControlName="password">
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn btn-outline-active" [disabled]='!loginDetails.valid'>Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>





