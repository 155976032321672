import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  dbValue: any;

  constructor(
    public db: AngularFirestore,
  ) { }
  getUser(userKey: any) {
    return this.db.collection('callEnquiries').doc(userKey).snapshotChanges();
  }

  updateUser(userKey: any, value: any) {
    value.nameToSearch = value.name.toLowerCase();
    return this.db.collection('callEnquiries').doc(userKey).set(value);
  }

  deleteUser(userKey: any) {
    return this.db.collection('callEnquiries').doc(userKey).delete();
  }

  getUsers() {
    return this.db.collection('callEnquiries').snapshotChanges();
  }
  getPhotoUsers() {
    return this.db.collection('userId').snapshotChanges();
  }

  searchUsers(searchValue: any) {
    return this.db.collection('callEnquiries', ref => ref.where('nameToSearch', '>=', searchValue)
      .where('nameToSearch', '<=', searchValue + '\uf8ff'))
      .snapshotChanges()
  }

  searchUsersByAge(value: any) {
    return this.db.collection('callEnquiries', ref => ref.orderBy('age').startAt(value)).snapshotChanges();
  }


  createUser(value: any) {
    console.log('createUser', value)

    return this.db.collection('callEnquiries').add({
      email: value.email,
      feedback: value.feedback,
      fullName: value.fullName,
      mobNo: value.mobileno,
      submit: true,
    });

  }
  photoShoot(value: any) {
    if (value.bookingFunction === "Wedding") {
     this.dbValue=  this.db.collection('userId').add({
        bookingName: value.bookingName,
        bookingMob: value.bookingMob,
        bookingEmail: value.bookingEmail,
        bookingCity: value.bookingCity,
        bookingAdd: value.bookingAdd,
        bookingFunction: value.bookingFunction,
        bookingDate:value.bookingDate,
        bookingTime:value.bookingTime,
        bookingCamera:value.bookingCamera,
        bookingDrones:value.bookingDrones,
        wedDOFPreWed:value.wedDOFPreWed,
        wedDOFRoka:value.wedDOFRoka,
        wedDOFRing:value.wedDOFRing,
        wedDOFTel:value.wedDOFTel,
        wedDOFHaldi:value.wedDOFHaldi,
        wedDOFMehndi:value.wedDOFMehndi,
        wedDOFBhat:value.wedDOFBhat,
        wedDOFSagai:value.wedDOFSagai,
        wedDOFBaraat:value.wedDOFBaraat,
        wedDOFReception:value.wedDOFReception,
        wedDOFPost:value.wedDOFPost
      })
    }
    console.log('Photoshoot Details', value)
    if(value.bookingFunction != "Wedding"){
    this.dbValue= this.db.collection('userId').add({
      bookingName: value.bookingName,
      bookingMob: value.bookingMob,
      bookingEmail: value.bookingEmail,
      bookingCity: value.bookingCity,
      bookingAdd: value.bookingAdd,
      bookingFunction: value.bookingFunction,
      bookingDate:value.bookingDate,
      bookingTime:value.bookingTime,
      bookingCamera:value.bookingCamera,
      bookingDrones:value.bookingDrones,
    })
  }
  return this.dbValue;
  }

}
