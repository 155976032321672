import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Workbook } from 'exceljs';

import * as fs from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {
  fileExtension= '.xlsx';
companyName ="Chandni Visual's"
  constructor() { }
  async generateExcel(jsonData: any[], header: string[], title: string, fileName: string) {
console.log("excel",jsonData,header,title,fileName);

    //Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(title);
    
    const companyNameHead = worksheet.addRow([this.companyName])
    worksheet.addRow([]);
    // Add new row
    const titleRow = worksheet.addRow([title]);

    // Set font, size and style in title row.
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 13, underline: 'double', bold: true };
    companyNameHead.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };

    

    // Blank Row
    worksheet.addRow([]);





    worksheet.mergeCells('A1:E2');


    // Blank Row
    worksheet.addRow([]);

    // Add Header row
    const headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    //tslint:disable-next-line: variable-name
    // Add Data and Conditional V
    console.log(jsonData.map(Object.values))

    var x = jsonData.map(Object.values)

    worksheet.addRows(x);

    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, };
      worksheet.getColumn(number).width = 30;

    });
    worksheet.addRow([]);


    // Footer Row
    const footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }, };

    worksheet.mergeCells(`A${footerRow.number}:D${footerRow.number}`);

    workbook.xlsx.writeBuffer().then((dataRow: any) => {
      const blob = new Blob([dataRow], { type: 'application/vnd.openxlsformat-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName + this.fileExtension);
    });


  }
}