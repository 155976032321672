<app-header></app-header>
<!-- <div *ngIf="!langChoose" class="mainContianer">

    <div  class="modal-xl " id="LanguageModal" tabindex="-1" role="dialog"
        aria-labelledby="languageChoose" aria-hidden="true">
        <div class="modal-dialog opacity-animate3 " role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                    <h3 class="mx-auto" id="languageModalTitle">Choose Language / भाषा चुनें</h3>
                </div>
                <div class="row mt-2 mb-2">
                    <div class="col-6 text-center">
                        <button type="button" class="btn btn-secondary" (click)="langSelected('english')"
                            data-dismiss="modal">English</button>
                    </div>
                    <div class="col-6 text-center">
                        <button type="button" class="btn btn-primary" (click)="langSelected('hindi')">हिन्दी</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div> -->
<div  class="adminContainer">
    <div class="row m-0 p-0">
        <div class="col-12 text-center">
            <h2 *ngIf='!uploadFlag'>Photoshoot Booked</h2>
            <h2 *ngIf='uploadFlag'>Upload Images</h2>
        </div>
        <div class="col-12 mt-3">
            <div class="d-flex m-3 justifyCenter">
                <button class="mr-3 "[ngClass]="!uploadFlag?'mainActiveBtn':'mainBtn'" (click)="details()">Booking Details</button>
                <button class=""[ngClass]="uploadFlag?'mainActiveBtn':'mainBtn'" (click)="upload()">Upload Image</button>
            </div>
            <div *ngIf='!uploadFlag' class="text-center">
                <button class="col-6 btn btn-outline-primary mb-3" (click)="exportExcel()">Download Excel</button>
               <div class="tableMainBox">
                <table class="mx-auto mb-3">
                    <thead class="tableHeading">
                    <tr>
                        <th>
                            <input type="checkbox" id="checkboxAll" [checked]="allChecked"
                                (click)="allSelected($event)">
                        </th>
                        <th>
                            Full Name
                        </th>
                        <th>
                            Mobile Number
                        </th>
                        <th>
                            Email Address
                        </th>
                        <th>
                            Address
                        </th>
                        <th>
                            Location of PhotoShoot
                        </th>
                        <th>
                            Type of Function
                        </th>
                        <th>
                            Date of Function
                        </th>
                        <th>
                            Time of Function
                        </th>
                        <th>
                            Camera Quantity
                        </th>
                        <th>
                            Drone Quantity
                        </th>

                    </tr>
                </thead>
                <tbody class="tableBody">
                    <tr *ngFor="let callList of callData;let i = index">
                        <td>
                            <input type="checkbox" id="checkbox{{i}}" [checked]="callList.checked"
                                (click)="selectedUser($event,i)">
                        </td>
                        <td>
                            {{callList.fullName}}
                        </td>
                        <td>
                            {{callList.mobNo}}
                        </td>
                        <td>
                            {{callList.email}}
                        </td>
                        <td>
                            {{callList.address}}
                        </td>
                        <td>
                            {{callList.locationofShoot}}
                        </td>
                        <td>
                            {{callList.typeOfFunction}}
                        </td>
                        <td>
                            {{callList.functionDate}}
                        </td>
                        <td>
                            {{callList.timeOfFunction}}
                        </td>
                        <td>
                            {{callList.cameraQuantity}}
                        </td>
                        <td>
                            {{callList.droneQuantity}}
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>




            <div *ngIf='uploadFlag'>

                <div class="d-flex justifyS pb-3 borderBottom">
                    <button class="btn btn-outline-secondary" (click)="typeSelected('About')">{{btnHeadings.firstContainer}}</button>
                    <button class="btn btn-outline-secondary" (click)="typeSelected('Wedding')">{{btnHeadings.secondContainer}}</button>
                    <button class="btn btn-outline-secondary" (click)="typeSelected('Personal')">{{btnHeadings.thirdContainer}}</button>
                    <button class="btn btn-outline-secondary" (click)="typeSelected('Wild')">{{btnHeadings.fourthContainer}}</button>
                    <button class="btn btn-outline-secondary" (click)="typeSelected('Event')">{{btnHeadings.fifthContainer}}</button>
                    <button class="btn btn-outline-secondary" (click)="typeSelected('Crousel')">{{btnHeadings.crouselImages}}</button>
                </div>
                
                <div class="text-center bkheading m-3">Upload {{headingFrame}} Photos</div>
                <div class="d-flex m-3 pb-3 justifyCenter borderBottom">
                <input type="file" id="file" #userPhoto (change)="onFileSelected($event)" name="image"
                    autocomplete="off" />
                    <div class="text-center">
                        <button class="px-4  uploadBtn" (click)="finalUpload()">Upload</button>
                    </div>
                </div>
            </div>


            <div class="row justifyS m-0" *ngIf='uploadFlag'>
                
                <div class="col-lg-5 col-md-5 col-sm-12   " *ngFor="let fileUpload of fileUploads">
                    <div class="imageMainBox">
                    <div class="imageshown" [ngStyle]="{'background-image': 'url(' + fileUpload.url + ')'}">
                    </div >
                </div>
                    <div class="text-right">
                      <button class="deleteBtn p-2 m-3 " (click)="deleteBtn(fileUpload)"></button>
                     </div>
                </div>
            </div>

        </div>
    </div>
</div>
<app-footer></app-footer>