<div class="bookingMainBox m-3 p-3">
    <div class="bookingHeading"></div>
    <div>
        <form class="bookingForm" autocomplete="off" [formGroup]="userBookingForm"
            (ngSubmit)="formSubmmision(userBookingForm.value)">
            <div>
                <label for="fname">Full Name</label>
                <input  type="text" minlength="3" [(ngModel)]="bfName" [ngClass]="bfName?'inputActiveBox':'inputBox'" formControlName="bookingName" id="fname"
                    name="full name">
            </div>
            <div>
                <label for="mobileNo.">Mobile Number</label>
                <input  type="tel" [maxlength]="10" [ngClass]="bfNumber?'inputActiveBox':'inputBox'" [(ngModel)]="bfNumber" id="mobileNo." formControlName="bookingMob"
                    name="Mobile Number">
            </div>
            <div>
                <label for="email">Email Id</label><br>
                <input  type="email" id="email" [ngClass]="bfemail?'inputActiveBox':'inputBox'"[(ngModel)]="bfemail" formControlName="bookingEmail" name="email">
            </div>
            <div>
                <label for="city">City of Photoshoot</label>
                <input  type="text" id="city" [ngClass]="bfCity?'inputActiveBox':'inputBox'" [(ngModel)]="bfCity" formControlName="bookingCity" name="city">
            </div>
            <div>
                <label for="address">Complete Address</label>
                <input  type="text" formControlName="bookingAdd" [ngClass]="bfAdderss?'inputActiveBox':'inputBox'" [(ngModel)]="bfAdderss" id="address" name="address">
            </div>
            <div>
                <label for="function" >Type of Function</label>
                <select id="function" formControlName="bookingFunction" name="function" [ngClass]="bfFunction?'inputActiveBox':'inputBox'" [(ngModel)]="bfFunction"
                    (change)="typeFunction(userBookingForm.value.bookingFunction)">
                    <option class="formOption" value="Wedding">Wedding</option>
                    <option value="BirthDay Party">BirthDay Party</option>
                    <option value="Cultural Event">Cultural Event</option>
                    <option value="Sports Event">Sports Event</option>
                    <option value="Portfolio Shoot">Portfolio Shoot</option>
                    <option value="Modelling Shoot">Modelling Shoot</option>
                    <option value="Kids Shoot">Kids Shoot</option>
                    <option value="Product Shoot">Product Shoot</option>
                    <option value="Office/building Shoot">Office/building Shoot</option>
                </select>
            </div>
            <div>
                <label for="date" *ngIf='!weddingFlag'>Date of Photoshoot</label>
                <input  type="date" formControlName="bookingDate" [ngClass]="bfDate?'inputActiveBox':'inputBox'" [(ngModel)]="bfDate" id="date" name="date"
                    *ngIf='!weddingFlag'>
            </div>
            <div>
                <label for="time" *ngIf='!weddingFlag'>Time of Photoshoot</label>
                <input  type="time" formControlName="bookingTime" [ngClass]="bfTime?'inputActiveBox':'inputBox'" [(ngModel)]="bfTime" id="time" name="time"
                    *ngIf='!weddingFlag'>
            </div>
            <div class="width95" *ngIf='weddingFlag'>
                <div class="d-flex justifyBetween">
                    <label for="doPreWed">Date of Pre-Wedding</label>
                    <input class="inputWedBox" formControlName='wedDOFPreWed' type="date">
                </div>
                <div class="d-flex justifyBetween">
                    <label for="doPreWed">Date of Roka</label>
                    <input class="inputWedBox" formControlName='wedDOFRoka' type="date">
                </div>
                <div class="d-flex justifyBetween">
                    <label for="doPreWed">Date of Ring</label>
                    <input class="inputWedBox" formControlName='wedDOFRing' type="date">
                </div>
                <div class="d-flex justifyBetween">
                    <label for="doPreWed">Date of Tel-ban</label>
                    <input class="inputWedBox" formControlName='wedDOFTel' type="date">
                </div>
                <div class="d-flex justifyBetween">
                    <label for="doPreWed">Date of Haldi</label>
                    <input class="inputWedBox" formControlName='wedDOFHaldi' type="date">
                </div>
                <div class="d-flex justifyBetween">
                    <label for="doPreWed">Date of Mehandi ki Raat</label>
                    <input class="inputWedBox" formControlName='wedDOFMehndi' type="date">
                </div>
                <div class="d-flex justifyBetween">
                    <label for="doPreWed">Date of Chak-Bhat</label>
                    <input class="inputWedBox" formControlName='wedDOFBhat' type="date">
                </div>
                <div class="d-flex justifyBetween">
                    <label for="doPreWed">Date of Sagai</label>
                    <input class="inputWedBox" formControlName='wedDOFSagai' type="date">
                </div>
                <div class="d-flex justifyBetween">
                    <label for="doPreWed">Date of Baraat</label>
                    <input class="inputWedBox" formControlName='wedDOFBaraat' type="date">
                </div>
                <div class="d-flex justifyBetween">
                    <label for="doPreWed">Date of Reception</label>
                    <input class="inputWedBox" formControlName='wedDOFReception' type="date">
                </div>
                <div class="d-flex justifyBetween">
                    <label for="doPreWed">Date of Post-Wedding</label>
                    <input class="inputWedBox" formControlName='wedDOFPost' type="date">
                </div>
                
            </div>

            <div>
                <label for="camera quantity">Camera Quantity</label>
                <input  type="number" formControlName="bookingCamera" [ngClass]="bfCamera?'inputActiveBox':'inputBox'" [(ngModel)]="bfCamera" id="camera quantity"
                    name="camera">
            </div>
            <div>
                <label for="drone quantity">Drone Quantity</label>
                <input  type="number" formControlName="bookingDrones" [ngClass]="bfDrone?'inputActiveBox':'inputBox'" [(ngModel)]="bfDrone" id="drone quantity" name="drone">
            </div>
            <div class="mt-2 text-center">
                <button type="submit" [disabled]='!userBookingForm.valid' class="mx-auto bookBtn">Book Your
                    Shoot</button>
            </div>
        </form>



        

    </div>
</div>