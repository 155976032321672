import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.scss']
})
export class BookingFormComponent implements OnInit {
  userBookingForm:FormGroup;
  weddingFlag: boolean;
  bfName:any;
  bfNumber:any;
  bfemail:any;
  bfCity:any;
  bfAdderss:any;
  bfFunction:any;
  bfDate:any;
  bfTime:any;
  bfCamera:any;
  bfDrone:any;
  constructor(private fb:FormBuilder, private firebaseService:FirebaseService,private http:HttpClient) {
    this.userBookingForm = this.fb.group({
      bookingName:['',Validators.required],
      bookingMob:['',Validators.required],
      bookingEmail:['',Validators.required],
      bookingCity:['',Validators.required],
      bookingAdd:['',Validators.required],
      bookingFunction:['',Validators.required],
      bookingDate:['',Validators.required],
      bookingTime:['',Validators.required],
      bookingCamera:['',Validators.required],
      bookingDrones:[''],
      wedDOFPreWed:[''],
      wedDOFRoka:[''],
      wedDOFRing:[''],
      wedDOFTel:[''],
      wedDOFHaldi:[''],
      wedDOFMehndi:[''],
      wedDOFBhat:[''],
      wedDOFSagai:[''],
      wedDOFBaraat:[''],
      wedDOFReception:[''],
      wedDOFPost:[''],
      
    })
   }

  ngOnInit(): void {
  }
  typeFunction(data){
    data === 'Wedding'?
    this.weddingFlag = true:
    this.weddingFlag = false;
    console.log("functionSelected",data);
    
  }
  formSubmmision(data){
    this.pushEmail(data);
    console.log("formData----",data);
    this.firebaseService.photoShoot(data).then(
      (res: any) => {
        console.log('sent',res)
        this.resetFields();
      }
      )
      
    
  }
  pushEmail(contactForm) {
    if (contactForm.valid) {
      const email = contactForm.value;
      const headers = new HttpHeaders({
         'Content-Type': 'application/json'
      });
      this.http.post('https://formspree.io/asdlf7asdf', {
         name: email.bookingName,
         replyto: email.bookingEmail,
         message: "Booking is confirmed with chandni visuals!"
      }, {
         'headers': headers
      }).subscribe(
         response => {
            console.log(response);
         }
      );
   }
  }
  resetFields() {
    this.userBookingForm = this.fb.group({
      bookingName:['',Validators.required],
      bookingMob:['',Validators.required],
      bookingEmail:['',Validators.required],
      bookingCity:['',Validators.required],
      bookingAdd:['',Validators.required],
      bookingFunction:['',Validators.required],
      bookingDate:['',Validators.required],
      bookingTime:['',Validators.required],
      bookingCamera:['',Validators.required],
      bookingDrones:[''],
      wedDOFPreWed:[''],
      wedDOFRoka:[''],
      wedDOFRing:[''],
      wedDOFTel:[''],
      wedDOFHaldi:[''],
      wedDOFMehndi:[''],
      wedDOFBhat:[''],
      wedDOFSagai:[''],
      wedDOFBaraat:[''],
      wedDOFReception:[''],
      wedDOFPost:[''],
    })
  }

  excelDownload(){

  }
}
