<div class="mainReviweBox">
    <div class="reviweHead mb-3">
        <h3 class="reviewHeading">
            See what
            Our Customers
            think of <span style="color: orangered;font-family:'Kaushan Script', cursive;">Chandni Visual's </span>
        </h3>
    </div>


    <div class="d-flex justifyCenter">
        
        <div *ngFor="let reviewIts of reviewDataArr;let i = index">
            <div class="mainBoxDiv mx-auto" *ngIf='i === count'>
                <div class="reviweBox">
                    <div class="mx-auto ngxAvtar">
                        <ngx-avatar name={{reviewIts.reviewerName}} size="150"></ngx-avatar>
                    </div>
                    
                    <div class="py-1">
                    
                    <div>
                        <div class="ml-1 quoteLeft">

                        </div>
                        <p class="pReviwe m-0 py-1">
                            {{reviewIts.reviewComment}}
                        </p>
                        <div class="ml-auto mr-3 quoteRight">

                        </div>
                    </div>
                    <h4 class="text-center reviweHead">{{reviewIts.reviewerName}}</h4>
                </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="d-flex p-2 arrowBox">
        <button class="previousBtn mr-2" (click)='beforeClick()' [disabled]='count === 0'></button>
        <button class="nextBtn" (click)='nextClick()' [disabled]='count === reviewDataArr.length - 1'></button>
    </div>
</div>
