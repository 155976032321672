<div class="mainfooterBox">
    <div>Contact Us</div>
    <div class="d-flex mainFooter">
        <div class="commonDiv">
            <div>chandnivisuals@xyz.com</div>
            <div>+91-9250925044</div>
            <div>Whatsapp: +91-9250925088</div>
            <div>
                Off/Home Rz-339, 394, Street Number 8, Shivpuri, West Sagarpur, Sagar Pur, New Delhi, Delhi 110046
            </div>
        </div>
        <div class="commonDiv text-center">
            
            <div class="aboutBtn" (click)="footerClicked('about')">ABOUT</div>
            <div class="aboutBtn" (click)="footerClicked('faq')">FAQ</div>
            <div>PRIVACY POLICY</div>
            <div class="aboutBtn" (click)="footerClicked('terms')">TREMS & CONDITIONS</div>
        </div>
        <div class="commonDiv"></div>
        <div class="commonDiv">
            <div class="imgBox text-center">
                <img class="cvLogo" [src]="icons.cvLogo">
            </div>
        </div>
    </div>
</div>