import { Component, OnInit } from '@angular/core';
import { DataInteractionService } from 'src/app/services/data-interaction.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  reviewDataArr: any;
  reviewFlag =[];
  count = 0;
  constructor(private dataService:DataInteractionService) { }

  ngOnInit(): void {
    this.getReviews();
  }
  getReviews(){
    this.dataService.getreviewData().subscribe((res:any)=>{
       this.getreviewTask(res.reviewData);
    })
  }
  getreviewTask(data){
    this.reviewDataArr =data;
  }
  nextClick(){
    this.count++;

  }
  beforeClick(){
    this.count --;
  }

}
