import { Component, HostListener, OnInit } from '@angular/core';
import { cvIcons } from 'src/app/models/cv-icons';
import { DataInteractionService } from 'src/app/services/data-interaction.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  icons;
  scrollPage: boolean=false;
  mobFlag: boolean;
  screenWidth: number;
  constructor(private dataService : DataInteractionService) { }

  ngOnInit(): void {
    this.icons = cvIcons.getImage();
    this.screenWidth = screen.availWidth;
    this.responsiveness(this.screenWidth);
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 50) {
      this.scrollPage =true;
     }
     else{
       this.scrollPage = false;
     }
    }

    responsiveness(screenWidth: number) {
      screenWidth < 1025 ?
        this.mobFlag = true :
        this.mobFlag = false;
    }

    headerClicked(value:string){
      const data = value
  this.dataService.clickforHome$.next(data)
    }
}
