import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataInteractionService } from 'src/app/services/data-interaction.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
@Input() cmsData;
exampleForm:FormGroup;
  cmsDataPH: any;
  submitflag: boolean;
  constructor(private fb:FormBuilder,private fireBase:FirebaseService,private dataService:DataInteractionService) { 
    this.exampleForm = this.fb.group({
      fullName:['',Validators.required],
      mobileno:['',Validators.required],
      email:['',Validators.required],
      feedback:['']

    })
  }

  ngOnInit(): void {
    console.log("cmsData",this.cmsData);
    
  this.cmsDataPH = this.cmsData
  }
  call(){}
  onSubmit(formValue:any){
    console.log('formdetails',formValue);
    this.submitflag  = true
    const formData ={
      email: formValue.email,
      feedback: formValue.feedback,
      fullName: formValue.fullName,
      mobileno: formValue.mobileno,
      
    }
    this.fireBase.createUser(formData).then((res:any)=>{
      console.log("callEnquiries",res);
      
    });
  }
  onScrolltop(){
    window.scroll(0,0)
  }

}
