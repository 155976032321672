import { Pipe, PipeTransform } from '@angular/core';
import { send } from 'process';

@Pipe({
  name: 'genderBased'
})
export class GenderBasedPipe implements PipeTransform {

  transform(value: unknown,dataValue:any): unknown {
    let sendBackValue :any;
    dataValue.gender === 'male'? sendBackValue = 'Mr '+value:
    dataValue.gender === 'female'? sendBackValue = 'Miss '+value:'';
    return sendBackValue;
  }

}
