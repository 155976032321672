import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  @Input() aboutWork
  bookingClicked:boolean
  constructor() { }

  ngOnInit(): void {
    console.log("about Work",this.aboutWork);
    
  }

}
