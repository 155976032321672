<div>
    <div class="m-3 p-0">
        <div class="m-0 my-4 text-center p-0 heading">Video Gallery</div>
        <div class="row justifySpace m-0 p-0">
            <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xs-12 iframBox p-2">
                <iframe class="video_s" src="https://www.youtube.com/embed/qYIzHA-2kyE"></iframe>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xs-12 iframBox p-2">
                <iframe class="video_s" src="https://www.youtube.com/embed/__cMIw4Ix5M"></iframe>
            </div>
        </div>
    </div>
</div>
