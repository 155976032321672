import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {AngularFireDatabaseModule }from'@angular/fire/database' 
import { AngularFireStorageModule,AngularFireStorageReference,
  AngularFireUploadTask
   } from '@angular/fire/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ImageContainerComponent } from './components/image-container/image-container.component';
import { HomeComponentComponent } from './components/home-component/home-component.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { BookingFormComponent } from './components/booking-form/booking-form.component';
import { LocateUsComponent } from './components/locate-us/locate-us.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';


import { AboutUsComponent } from './components/about-us/about-us.component';
import { AdminComponent } from './components/admin/admin.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { AvatarModule } from 'ngx-avatar';
import { TermsandconditionsComponent } from './components/termsandconditions/termsandconditions.component';
import { BrokenImageComponent } from './components/broken-image/broken-image.component';
import { LoginComponent } from './components/login/login.component';
import { CardImagesComponent } from './components/card-images/card-images.component';
import { VideoComponent } from './components/video/video.component';
import { FaqComponent } from './components/faq/faq.component';
import { AboutCamerasComponent } from './components/about-cameras/about-cameras.component';
import { TestCheckComponent } from './components/test-check/test-check.component';
import { GenderBasedPipe } from './models/gender-based.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ImageContainerComponent,
    HomeComponentComponent,
    ContactFormComponent,
    BookingFormComponent,
    LocateUsComponent,
    CarouselComponent,
    AboutUsComponent,
    AdminComponent,
    ReviewsComponent,
    TermsandconditionsComponent,
    BrokenImageComponent,
    LoginComponent,
    CardImagesComponent,
    VideoComponent,
    FaqComponent,
    AboutCamerasComponent,
    TestCheckComponent,
    GenderBasedPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig,'cloud'),
    AngularFirestoreModule, // Only required for database features
    AngularFireStorageModule, 
    AngularFireDatabaseModule,
    AvatarModule
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
