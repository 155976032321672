<div class="mainImageContainer">
    <div class="text-center bkheading m-3">{{clickedHead}}</div>
    <div class="row m-0 justifyS ">
                
        <div class="col-lg-5 col-md-5 col-sm-12 imageMainBox" *ngFor="let fileUpload of fileUploads">
    
            <div class="imageshown" [ngStyle]="{'background-image': 'url(' + fileUpload.url + ')'}">
            </div >
        </div>
    </div>
</div>


