import { Component, OnInit } from '@angular/core';
import { cvIcons } from 'src/app/models/cv-icons';
import { DataInteractionService } from 'src/app/services/data-interaction.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  icons: any;

  constructor(private dataService:DataInteractionService) { }

  ngOnInit(): void {
    this.icons = cvIcons.getImage();
  }
  footerClicked(value:string){
    const data = value
this.dataService.clickforHome$.next(data)
  }
}
