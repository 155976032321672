import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { DataInteractionService } from 'src/app/services/data-interaction.service';

@Component({
  selector: 'app-image-container',
  templateUrl: './image-container.component.html',
  styleUrls: ['./image-container.component.scss']
})
export class ImageContainerComponent implements OnInit {
@Input () imgData
  headingFrame: any;
  fileUploads: { key: string; name: string; url: string; file: File; }[];
  clickedHead: any;
  constructor(private dataService:DataInteractionService) { }

  ngOnInit(): void {
    console.log("imgData",this.imgData);
     this.getHeading(this.imgData);
    this.getImagesData(this.imgData);
  }

  getHeading(value:string){
    value === 'Wedding'?
    this.clickedHead = 'Wedding Gallery':
    value === 'Personal'?
    this.clickedHead = 'Personal Portfolio Gallery':
    value === 'Wild'?
    this.clickedHead = "Wildlife & Nature":
    value === 'Event'?
    this.clickedHead='Event Gallery':'';

  }
  getImagesData(data){
    data === 'Wedding' ?
    this.fileUploads = this.dataService.imagesWedGallery:
    data === 'Personal' ?
      this.fileUploads = this.dataService.imagesPerGallery:
      data === 'Wild' ?
        this.fileUploads = this.dataService.imagesWildGallery:
        data === 'Event' ?
          this.fileUploads = this.dataService.imagesEventGallery:'';
  }
  // getImages(data) {
  //   this.dataService.getFiles(6,data).snapshotChanges().pipe(
  //     map(changes =>
  //       // store the key
  //       changes.map(c => ({
  //         key: c.payload.key,
  //         ...c.payload.val()
  //       }))
  //     )
  //   ).subscribe(fileUploads => {
  //     this.fileUploads = fileUploads;
  //   });
  // }

}
