<div>
    <div class="faqHead"> Frequently Asked Question's:</div>
    <div class="faqMainBox p-3 mx-auto">
        <div class="mt-3" *ngFor="let faqIterate of toggleDataArr">
            <button class="headBtn" [ngClass]="faqIterate.toggleFlag?'toggleUp':'toggleDown'" (click)="toggleBtn(faqIterate)">
                {{faqIterate.toggleQuestion}}
            </button>
            <div class="subHead " *ngIf='faqIterate.toggleFlag'>
                {{faqIterate.toggleAnswer}}
            </div>
        </div>
    </div>
</div>