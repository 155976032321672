import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  toggleFlag: boolean;
  toggleDataArr=[]

  constructor() { 

    this.toggleDataArr =[
      {toggleQID:1001,toggleQuestion:" What does manual mode mean?",toggleAnswer:"Manual mode puts the photographer in complete control of how the photo will turn out. You have to manually set both the aperture, ISO, and the shutter speed. You can take this a few steps further and control the white balance, too. The camera will not make any automatic changes to the exposure. Your camera will still guide you to the best exposure with its built in meter.",toggleFlag:false},
      {toggleQID:1002,toggleQuestion:"Do you really have to edit all your photos?",toggleAnswer:"In a word, yes. While it is best to nail an image in camera, editing is how it all comes together to become a finished image. Some photographers are more artistic with their editing and some are more clean. This is really just a personal preference and will come with time.",toggleFlag:false},
      {toggleQID:1003,toggleQuestion:"What gear do I need to achieve great photos?",toggleAnswer:"The gear you have in your bag is a personal preference and really depends on what type of photography you are interested in. It is recommended to start your journey with the “nifty 50,” AKA a 50mm prime lens. All major manufacturers have great 50mm options and this really is a fantastic starter lens. After you have explored with the 50mm lens, you can try your hand at more wide angle and telephoto focal lengths. If zoom is appealing to you, there are plenty of zoom lenses out there, however you will hear many professional photographers who swear by prime lenses (lenses that do not zoom). Here are a few lens examples: 24mm, 35mm, 50mm,100mm (macro), 135mm ,200mm",toggleFlag:false},
      {toggleQID:1004,toggleQuestion:"What is white balance?",toggleAnswer:"Again, a whole article could be written on this question alone so I will try and keep this answer brief. Do you ever wonder why sometimes your subject looks bright yellow or orange, or why sometimes they look grey or blue? This is because your white balance is off.To define this very simply, in reference to digital photography, white balance means that the colors in your image are true to life. White balance is measured by numbers, lower being cooler and higher being warmer. Here is a great diagram that explains white balance.",toggleFlag:false},
      {toggleQID:1005,toggleQuestion:"Can i Take Photo with my old camera?",toggleAnswer:"Yes,if this is on good condition then yes but you should change the lens and try new.",toggleFlag:false},
      
    ]
  }

  ngOnInit(): void {
  }
toggleBtn(value:any){
  this.toggleDataArr.forEach(element => {
    element.toggleQID === value.toggleQID ?
    element['toggleFlag']=true:
    element['toggleFlag']=false;
  });
}
}
