// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyDbpVM2ql0LSfjjbjJX-AUAyNMN-Wj9Qno",
  authDomain: "chandnivisuals-photodb.firebaseapp.com",
  projectId: "chandnivisuals-photodb",
  storageBucket: "gs://chandnivisuals-photodb.appspot.com",
  messagingSenderId: "183392460140",
  appId: "1:183392460140:web:3a46d51b54b3550e0a2749",
  measurementId: "G-GRY8NY3WKQ"
  }
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
