<div>
    <!-- Header -->
    <header>
        <div class="mainHeadBox">
            <div class="d-flex headContent">
                <div class="">
                    <h3 class="companyNameMargin secondaryColor font38">LIFECHART</h3>
                </div>
                <div class="">
                    <button class="navigationBtn secondaryColor">Home</button>
                    <button class="navigationBtn secondaryColor">About Us</button>
                    <button class="navigationBtn secondaryColor">Services</button>
                    <button class="navigationBtn secondaryColor">Coaches</button>
                    <button class="navigationBtn secondaryColor">Testimonial</button>
                    <button class="navigationBtn secondaryColor">Contact Us</button>
                </div>
            </div>
            <div class="text-center mx-auto subHeadBox">
                <h2 class="subBox primaryColor">YOGA</h2>
                <p class="paraBox pb-4 primaryColor">Grow your inner health</p>
            </div>
        </div>
    </header>
    <!-- home -->
    <div>
        <div class="row mx-0 p-0" style="margin-top: -22px;">
            <div class="col-3 imgBox imgBox1 m-0 p-0">
                <div class="bgColorP"></div>
                <div class="absoluteBox">
                    <div class="whiteHr"> </div>
                    <div>
                        <h5 class="font30">Small Batches</h5>
                        <p class="m-0 font16">For a more personalised class setting.</p>
                    </div>
                </div>
                <div class="cartIconBox caartIcon1"> </div>
            </div>
            <div class="col-3 imgBox imgBox2 m-0 p-0">
                <div class="bgColorP">
                </div>
                <div class="absoluteBox">
                    <div class="whiteHr"> </div>
                    <div>
                        <h5 class="font30">Personalised Onboarding</h5>
                        <p class="m-0 font16">To help our trianers curate session better for you.</p>
                    </div>
                </div>
                <div class="cartIconBox caartIcon2"> </div>
            </div>
            <div class="col-3 imgBox imgBox3 m-0 p-0">
                <div class="bgColorP">
                </div>
                <div class="absoluteBox">
                    <div class="whiteHr"> </div>
                    <div>
                        <h5 class="font30">Flexible Schedules</h5>
                        <p class="m-0 font16">available for 6:30 AM to 8:30 PM</p>
                    </div>
                </div>
                <div class="cartIconBox caartIcon3"> </div>
            </div>
            <div class="col-3 imgBox imgBox4 m-0 p-0">
                <div class="bgColorP">
                </div>
                <div class="absoluteBox">
                    <div class="whiteHr"> </div>
                    <div>
                        <h5 class="font30">Free App Access</h5>
                        <p class="m-0 font16">To help our trianers curate session better for you.</p>
                    </div>
                </div>
                <div class="cartIconBox caartIcon4"> </div>

            </div>
        </div>
        <div class="mainBox">
            <div class="mb-4">
                <p>Our classes</p>
                <h3>Best <b>Interactive</b> <br> Classes</h3>
                <div class="row text-center justifyS">
                    <div class="mainContainer">
                        <div class="imgContainer box1"></div>
                        <p class="subTitle">Yoga for PCOS</p>
                    </div>
                    <div class="mainContainer">
                        <div class="imgContainer box2"></div>
                        <p class="subTitle">Postnatal yoga and Breathwork</p>
                    </div>
                    <div class="mainContainer">
                        <div class="imgContainer box3"></div>
                        <p class="subTitle">Postnatal yoga and Breathwork</p>
                    </div>
                    <div class="mainContainer">
                        <div class="imgContainer box4"></div>
                        <p class="subTitle">Yoga class for wellness</p>
                    </div>
                    <div class="mainContainer">
                        <div class="imgContainer box5"></div>
                        <p class="subTitle">Meditation for migraines</p>
                    </div>
                </div>
            </div>

            <div class="mb-4">
                <p>Our classes</p>
                <h3>Best <b>Interactive</b> <br> Classes</h3>
                <div class="row mt-5 text-center justifyS">
                    <div class="mainContainerRegister">
                        <div class="iconBox iconBoxImage iconPath1 mb-3 mx-auto"></div>
                        <h5>Yoga for Diabetic</h5>
                        <p class="terniaryColor  px-3">We do both internal and external repairs. The staff of our
                            company is
                            fully
                            equipped with qualified builders, designers</p>
                        <button class="registerBtn">Register Now</button>
                    </div>
                    <div class="mainContainerRegister">
                        <div class="iconBox iconBoxImage iconPath2 mb-3 mx-auto"></div>
                        <h5>Yoga for PCOS</h5>
                        <p class="terniaryColor  px-3">We do both internal and external repairs. The staff of our
                            company is
                            fully
                            equipped with qualified builders, designers</p>
                        <button class="registerBtn">Register Now</button>
                    </div>
                    <div class="mainContainerRegister">
                        <div class="iconBox iconBoxImage iconPath3 mb-3 mx-auto"></div>
                        <h5>Yoga for Wellness</h5>
                        <p class="terniaryColor  px-3">We do both internal and external repairs. The staff of our
                            company is
                            fully
                            equipped with qualified builders, designers</p>
                        <button class="registerBtn">Register Now</button>
                    </div>
                    <div class="mainContainerRegister">
                        <div class="iconBox iconBoxImage iconPath4 mb-3 mx-auto"></div>
                        <h5>Yoga for Meditation</h5>
                        <p class="terniaryColor  px-3">We do both internal and external repairs. The staff of our
                            company is
                            fully
                            equipped with qualified builders, designers</p>
                        <button class="registerBtn">Register Now</button>
                    </div>
                </div>
            </div>
            <div class="mt-5 mb-4">
                <p>Our price</p>
                <h3>Our <b>Pricing</b> <br> Chart</h3>
                <div class="row mt-5 text-center justifyS">
                    <div class="pricingContainer mx-auto">
                        <div class="pricingIconBasic pricingIcon mx-auto mb-4"></div>
                        <h5>Basic Plan</h5>
                        <p class="terniaryColor">Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                            Lorem Ipsum has been the industry's standard dummy</p>
                        <hr>
                        <b>$40/Month</b>
                        <div class="mt-5">
                            <button class="startedBtn">Get Started</button>
                        </div>
                    </div>
                    <div class="pricingContainer mx-auto">
                        <div class="pricingIconPro pricingIcon mx-auto mb-4"></div>
                        <h5>Professional Plan</h5>
                        <p class="terniaryColor">Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                            Lorem Ipsum has been the industry's standard dummy</p>
                        <hr>
                        <b>$140/Month</b>
                        <div class="mt-5">
                            <button class="startedBtn">Get Started</button>
                        </div>
                    </div>
                    <div class="pricingContainer mx-auto">
                        <div class="pricingIconStand  pricingIcon mx-auto mb-4"></div>
                        <h5>Standard Plan</h5>
                        <p class="terniaryColor">Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                            Lorem Ipsum has been the industry's standard dummy</p>
                        <hr>
                        <b>$300/Month</b>
                        <div class="mt-5">
                            <button class="startedBtn">Get Started</button>
                        </div>
                    </div>


                </div>
            </div>
            <div class="mt-5 mb-4">
                <p>Viewers</p>
                <h3>What <b>Viewers</b> <br> Says</h3>
                <div class="row mt-5 text-center justifyS">
                    <div class="reviewContainer">


                        <p class="text-light px-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy</p>


                        <div class="reviewer">
                            <div class="row m-0 mx-auto profile secondaryColor">
                                <div class="col-4 m-0  p-0">
                                    <div class="profilepic mx-auto mt-1"></div>
                                </div>
                                <div class="col-8 text-left m-0 p-0">
                                    <div>ABC Yadav</div>
                                    <small>OKLA Labs</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="reviewContainer">


                        <p class="text-light px-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy</p>


                        <div class="reviewer">
                            <div class="row m-0 mx-auto profile secondaryColor">
                                <div class="col-4 m-0  p-0">
                                    <div class="profilepic mx-auto mt-1"></div>
                                </div>
                                <div class="col-8 text-left m-0 p-0">
                                    <div>ABC Yadav</div>
                                    <small>OKLA Labs</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="reviewContainer">


                        <p class="text-light px-1">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy</p>

                        <div class="reviewer">
                            <div class="row m-0 mx-auto profile secondaryColor">
                                <div class="col-4 m-0 p-0 ">
                                    <div class="profilepic mx-auto mt-1"></div>
                                </div>
                                <div class="col-8 text-left m-0 p-0">
                                    <div>ABC Yadav</div>
                                    <small>OKLA Labs</small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="mt-5 mb-4">
                <div class="row mt-3 p-0 justifyS">
                    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-xl-8 m-0 p-0">
                        <p>Contact Us</p>
                        <h3>Get <b>In Touch</b> <br> Now</h3>
                        <div class="d-flex mt-5 text-center justifyS">
                            <div class="primaryHorizontal mt-2 "></div>
                            <div class="px-5  text-left">
                                <b>Our range of services is very large, and we are
                                    constantly expanding it. Starting from the creation
                                    of an architectural project and design and ending
                                    with its implementation.</b>
                                <div class="d-flex mt-4 pb-3 border-bottom">
                                    <div class="profilepic"></div>
                                    <div>
                                        <div class="pl-4">
                                            <b>George Brown</b>
                                        </div>
                                        <div class="pl-4 terniaryColor">
                                            <small>RISK MANAGER</small>
                                        </div>
                                        <div class="terniaryColor">
                                            <ul>
                                                <li>+1 890 67 6543</li>
                                                <li>acbvvssk@gmial.com</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex mt-4 pb-3 ">
                                    <div class="profilepic"></div>
                                    <div>
                                        <div class="pl-4">
                                            <b>Angelina Wilson</b>
                                        </div>
                                        <div class="pl-4 terniaryColor">
                                            <small>BUSSINESS MANAGER</small>
                                        </div>
                                        <div class="terniaryColor">
                                            <ul>
                                                <li>+1 890 67 6543</li>
                                                <li>acbvvssk@gmial.com</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-xl-4 m-0 p-0 ">
                        <form>
                            <div class="bgBlack py-2 px-4">
                                <div class="my-3 text-light ">Send a Request</div>
                                <div class="posRel">
                                    <input class="w-100 mt-4 terniaryColor formBorder" type="text"
                                        placeholder="Jason William">
                                    <span class="asterik1">*</span>
                                </div>
                                <div class="posRel">
                                    <input class="w-100 mt-4 terniaryColor formBorder" type="email"
                                        placeholder="Email Address ">
                                    <span class="asterik2">*</span>
                                </div>
                                <div class="posRel">
                                    <input class="w-100 mt-4 terniaryColor formBorder" type="tel"
                                        placeholder="Phone Number">
                                    <span class="asterik3">*</span>
                                </div>
                                <input class="w-100 mt-4 terniaryColor formBorder" type="text"
                                    placeholder="Service Name">
                                <textarea class="w-100 mt-4 terniaryColor formBorder" type=""
                                    placeholder="Message (Optional)"></textarea>
                                <button class="connect mt-5 mb-5 ml-2" type="submit">Connect Now</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Footer -->
    <footer class="bgMain">
        <div class="footerBox text-light">
            <h3 class="p-2">Life Chart</h3>
            <div class="row m-0 p-0">
                <div class="col-3">

                    <p>Get Wilmër and discover streamlined website construction today! Powerful elements, tons of
                        flexible layouts and more inside.</p>

                </div>
                <div class="col-3">
                    <div>
                        <b>Useful Links</b>
                    </div>
                    <div>About Us</div>
                    <div>Terms & Conditions</div>
                    <div>Privacy Policy</div>
                </div>
                <div class="col-3">
                    <div><b>Contact Us</b></div>
                    <div>423 London watch street united
                        kingdom USA</div>
                    <div>
                        +91 1452369870 +91 7854963210
                    </div>
                    <div>
                        LifeChart@gmail.com
                    </div>
                </div>
                <div class="col-3">
                    <div><b>Social Insta</b></div>
                </div>
            </div>
        </div>
    </footer>
</div>