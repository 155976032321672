import { Component, OnInit } from '@angular/core';
import { GenderBasedPipe } from '../../models/gender-based.pipe';

@Component({
  selector: 'app-test-check',
  templateUrl: './test-check.component.html',
  styleUrls: ['./test-check.component.scss']
})
export class TestCheckComponent implements OnInit {
dataArr =[];
genderFlag:boolean;
  filterData: any;
  dataHandle: any[];
  
  constructor() {
    this.dataArr = [
      {id:102,name:"ABC",gender:"male"},
      {id:103,name:"XYZ",gender:"male"},
      {id:104,name:"PQR",gender:"female"},
    ]
   }

  ngOnInit(): void {
   this.getTest(1); 
  }
  getTest(num:number){
    // debugger
    // let data = [1,1,2,3,5,8,13,21,34]
    let dataArr=[];
    for(let i = 0 ; i<num;i++){
      if(i===0){
        const value= 1
        dataArr.push(value);
      }else if(i===1){
        const value= 1
        dataArr.push(value);
      }else{
        const value = dataArr[dataArr.length-1] + dataArr[dataArr.length-2]
        dataArr.push(value);
      }
    }
    this.dataHandle = dataArr
    console.log("dataArrrr",this.dataHandle);

  }

}
