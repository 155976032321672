<app-header class="animateBody" ></app-header>
<!-- <div *ngIf="!langChoose" class="mainContianer">

<div  class="modal-xl mx-auto" id="LanguageModal" tabindex="-1" role="dialog"
    aria-labelledby="languageChoose" aria-hidden="true">
    <div class="modal-dialog opacity-animate3 " role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h3 class="mx-auto" id="languageModalTitle">Choose Language / भाषा चुनें</h3>
            </div>
            <div class="row mt-2 mb-2">
                <div class="col-6 text-center">
                    <button type="button" class="btn btn-secondary" (click)="langSelected('english')"
                        data-dismiss="modal">English</button>
                </div>
                <div class="col-6 text-center">
                    <button type="button" class="btn btn-primary" (click)="langSelected('hindi')">हिन्दी</button>
                </div>
            </div>
        </div>
    </div>
</div>
</div> -->

<!-- <div class="mainAniBox " *ngIf="!loaderFlag">
    <div class="mainBoxAnimation">
        <img class="imgBoxAnimation" src="../../../assets/images/cvGif.gif">
    </div>
</div> -->


<div class="  mainChandniContianer" >
    <!-- Navigation Bar -->
    <!-- <div class="row m-0"> -->
    <!-- <div class="m-0 p-0 imgNavBanner col-2" *ngIf="!mobFlag">
            <img src="../../../assets/images/navBar.png">
        </div> -->
    <!-- <div class="m-0 p-0" [ngClass]="mobFlag?'col-12':'col-12'"> -->
    <!-- <nav class="navbar navbar-expand-lg p-0 navbar-light "> -->
    <div *ngIf='mobFlag' class="text-center " [ngClass]="scrollPage?'toggleMainBox':'toggleMainBoxScroll'">
        <button class="navbar-toggler btn-block mx-auto" type="button" (click)='toggleClicked = !toggleClicked'
            [ngClass]="toggleClicked?'toggleBtnUp':'toggleBtnDown'" data-toggle="collapse" data-target="#navMenu"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <!-- <span class="navbar-toggler-icon"></span> -->
        </button>
    </div>
    <div class="m-0 p-0 " *ngIf='toggleClicked' [ngClass]="scrollPage?'cvJustifyScroll':'cvjustify'">
        <div class=" mainNavBox row mx-0  px-2   " *ngIf='toggleClicked' [ngClass]="mobFlag?'collapse':''" id="navMenu">
            <div class="col-lg-1 col-xl-1 col-md-3 col-sm-1 col-4 p-0 navItem">
                <button (click)="navBtn('about');" class=" p-2 txtColor " data-toggle="collapse"
                    data-target=".navbar-collapse.show">
                    <div class="navLinkMainBox">
                        <img class="imgBox" [src]='icons.cvLogo'>
                        <!-- <p class="m-0">
                                        {{textToShow.firstContainer}}
                                    </p> -->
                    </div>
                </button>
            </div>
            <div class="col-lg-1 col-xl-1 col-md-3 col-sm-1 col-4 p-0 navItem">
                <button (click)="navBtn('Wedding')" class=" pl-2 txtColor" data-toggle="collapse"
                    data-target=".navbar-collapse.show">
                    <div class="navLinkMainBox">
                        <img class="imgBox" [src]='icons.weddingLogo'>
                        <p class="m-0">
                            {{textToShow.secondContainer}}
                        </p>
                    </div>

                </button>
            </div>
            <div class="col-lg-1 col-xl-1 col-md-3 col-sm-1 col-4 p-0 navItem">
                <button (click)="navBtn('Personal')" class=" pl-2 txtColor" data-toggle="collapse"
                    data-target=".navbar-collapse.show">
                    <div class="navLinkMainBox">
                        <img class="imgBox" [src]='icons.portfolioLogo'>
                        <p class="m-0">
                            {{textToShow.thirdContainer}}
                        </p>
                    </div>
                </button>
            </div>
            <div class="col-lg-1 col-xl-1 col-md-3 col-sm-1 col-4 p-0 navItem">
                <button (click)="navBtn('Wild')" class=" pl-2 txtColor" data-toggle="collapse"
                    data-target=".navbar-collapse.show">
                    <div class="navLinkMainBox">
                        <img class="imgBox" [src]='icons.wildLogo'>
                        <p class="m-0">
                            {{textToShow.fourthContainer}}
                        </p>
                    </div>
                </button>
            </div>
            <div class="col-lg-1 col-xl-1 col-md-3 col-sm-1 col-4 p-0 navItem">
                <button (click)="navBtn('Event')" class=" pl-2 txtColor" data-toggle="collapse"
                    data-target=".navbar-collapse.show">
                    <div class="navLinkMainBox">
                        <img class="imgBox" [src]='icons.eventLogo'>
                        <p class="m-0">
                            {{textToShow.fifthContainer}}
                        </p>
                    </div>
                </button>
            </div>
            <div class="col-lg-1 col-xl-1 col-md-3 col-sm-1 col-4 p-0 navItem">
                <button (click)="navBtn('Video')" class=" pl-2 txtColor" data-toggle="collapse"
                    data-target=".navbar-collapse.show">
                    <div class="navLinkMainBox">
                        <img class="imgBox" [src]='icons.videoLogo'>
                        <p class="m-0">
                            {{textToShow.sixthContainer}}
                        </p>
                    </div>
                </button>
            </div>
            <div class="col-lg-1 col-xl-1 col-md-3 col-sm-1 col-4 p-0 navItem">
                <button (click)="navBtn('bookshoot')" class=" pl-2 txtColor" data-toggle="collapse"
                    data-target=".navbar-collapse.show">
                    <div class="navLinkMainBox">
                        <img class="imgBox" [src]='icons.bookingLogo'>
                        <p class="m-0">
                            {{textToShow.formHeading}}
                        </p>
                    </div>
                </button>
            </div>
            <div class="col-lg-1 col-xl-1 col-md-3 col-sm-1 col-4 p-0 navItem">
                <button (click)="navBtn('locate')" class=" pl-2 txtColor" data-toggle="collapse"
                    data-target=".navbar-collapse.show">
                    <div class="navLinkMainBox">
                        <img class="imgBox" [src]='icons.locateLogo'>
                        <p class="m-0">
                            {{textToShow.locateUsHeading}}
                        </p>
                    </div>
                </button>
            </div>


        </div>
    </div>
    <!-- </nav> -->

    <!-- </div> -->
    <!-- </div> -->
    <div class="brokenImage"></div>

    <div class="clickedContainer" *ngIf="aboutFlag">
        <!-- <div *ngIf="imagesCrouselGallery"> -->
        <app-about-us [aboutWork]="cardImagesCrouselFlag"></app-about-us>
        <!-- </div> -->
    </div>
    <div class="clickedContainer" *ngIf="weddingFlag">
        <app-image-container [imgData]='typeofImages'></app-image-container>
    </div>
    <div class="clickedContainer" *ngIf="personalFlag">
        <app-image-container [imgData]='typeofImages'></app-image-container>
    </div>
    <div class="clickedContainer" *ngIf="wildFlag">
        <app-image-container [imgData]='typeofImages'></app-image-container>
    </div>
    <div class="clickedContainer" *ngIf="eventFlag">
        <app-image-container [imgData]='typeofImages'></app-image-container>
    </div>
    <div class="clickedContainer" *ngIf="bookshootFlag">
        <app-booking-form></app-booking-form>
    </div>
    <div class="clickedContainer" *ngIf="videoFlag">
        <app-video></app-video>
    </div>
    <div class="clickedContainer" *ngIf="locateFlag">
        <app-locate-us></app-locate-us>
    </div>
    <div class="clickedContainer" *ngIf="termsFlag">
        <app-termsandconditions></app-termsandconditions>
    </div>
    <div class="contactIcon" *ngIf="permanentFlag">
        <app-contact-form [cmsData]="textToShow"></app-contact-form>
    </div>
    <div class="clickedContainer" *ngIf="faqFlag">
        <app-faq></app-faq>
    </div>
    <div class="m-0 p-0" *ngIf="aboutFlag">
        <div class="my-3" *ngIf="cardImagesWedFlag">
            <app-card-images [cardData]="weddingHead"></app-card-images>
        </div>
    </div>

    <div class="mainCardBox py-2 my-3" *ngIf="aboutFlag">
        <div class="headCard py-2 mx-auto px-3">
            <h4 class=" headStyle"> Simple steps to book your shoot</h4>
        </div>

        <div class="stepsBox d-flex mx-auto">
            <div class="steps p-2 mx-auto" *ngIf="cardNav1">
                <div class="imgTop">
                    <div class="imgCard mx-auto">
                        <img class="imgBoxAnimation" src="../../../assets/images/clickBtn.svg">
                    </div>
                </div>
                <b class=" stepsStyle p-2">Click on "Book Your Shoot"</b>
            </div>
            <div class="steps p-2 mx-auto" *ngIf="cardNav2">
                <div class="imgTop">
                    <div class="imgCard mx-auto">
                        <img class="imgBoxAnimation" src="../../../assets/images/fillForm.svg">
                    </div>
                </div>
                <b class=" stepsStyle p-2">Please fill up your details ! </b>
            </div>
            <div class="steps p-2 mx-auto" *ngIf="cardNav3">
                <div class="imgTop">
                    <div class="imgCard mx-auto">

                    </div>
                </div>
                <b class=" stepsStyle p-2">click on book your photoshoot</b>
            </div>
            <div class="steps p-2 mx-auto" *ngIf="cardNav4">
                <div class="imgTop">
                    <div class="imgCard mx-auto">
                        <img class="imgBoxAnimation" src="../../../assets/images/emailConfirm.svg">
                    </div>
                </div>
                <b class=" stepsStyle p-2">Get confirmation of booking on your E-mail</b>
            </div>
        </div>
        <div class="bkBtnMain">
            <button (click)="cardChange(1)" class="bkBtn ">1</button>
            <button (click)="cardChange(2)" class="bkBtn ">2</button>
            <!-- <button (click)="cardChange(3)" class="bkBtn ">3</button> -->
            <button (click)="cardChange(4)" class="bkBtn ">3</button>
        </div>
    </div>

    <div class="bookingBox d-flex" *ngIf="aboutFlag">
        <div class="clockBox"></div>
        <div>
            <div class="headingLine">
                <h1 class="savingHead">Saving your time and effort</h1>
            </div>
            <div class="text-center">
                <p class="subHeadLine mx-auto">
                    Consider all the time you could save by booking your photoshoot online.<br>
                    Book you requirements rest we'll take care.
                </p>
            </div>
            <div class="text-center">
                <button type="button" (click)="bookingBtn()" class=" m-0 p-0"
                    [ngClass]="bookingClicked?'btnDivActive':'btnDiv'">
                    <p class="btnLb m-0"> Book Your Shoot </p>
                </button>
            </div>
        </div>
    </div>

    <div class="m-0 p-0" *ngIf="aboutFlag">
        <div class="my-3" *ngIf="cardImagesWildFlag">
            <app-card-images [cardData]="wildHead"></app-card-images>
        </div>
    </div>
    <!-- <div class="my-3" *ngIf="aboutFlag">
        <app-card-images [cardData]='Personal'></app-card-images>
    </div> -->

</div>

<app-footer class="animateBody" *ngIf="loaderFlag"></app-footer>