import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginDetails:FormGroup
  constructor(private fb:FormBuilder) { 
    this.loginDetails = this.fb.group({
      userName:['',Validators.required],
      password:['',Validators.required]
    })
  }

  ngOnInit(): void {
  }
  loginFormSubmit(value){
    console.log('formValue',value);
    
  }
}
