<ngb-carousel #carousel [interval]="1000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus" (slide)="onSlide($event)">
    <ng-template ngbSlide *ngFor="let img of fileUploads; index as i">
      <!-- <div class="carousel-caption">
        
      </div> -->
      <!-- <a href="img.url" target="_blank" rel="nofollow noopener noreferrer"> -->
        <div class="picsum-img-wrapper">
          <img class="imgCrousel" [src]="img.url" >
        </div>
      <!-- </a> -->
    </ng-template>
  </ngb-carousel>