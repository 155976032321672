import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { DataInteractionService } from 'src/app/services/data-interaction.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { FileUpload } from '../../models/file-upload';
import { cvVariables } from '../../models/cvVariables';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  selectedFile: File = null;
  urlofFile;
  urlName;
  cvVar: cvVariables;
  downloadURL: Observable<string>;
  callData: any;
  allChecked: boolean;
  selectedFiles: FileList;
  currentFileUpload: FileUpload;
  percentage: number;
  header: string[];
  reportTitle: string = "Booking Shoot List";
  reportName: string = "Booking Shoot List";
  uploadFlag: boolean;
  fileUploads: { key: string; name: string; url: string; file: File; }[];
  fileSelected: any;
  fileSelectedFlag: boolean;
  langChoose: boolean;
  btnHeadings: any;
  headingFrame: string;
  cmsDataArr: any;

  constructor(private dataService: DataInteractionService, private db: AngularFireDatabase, private firebaseService: FirebaseService, private exportService: ExportExcelService, private storage: AngularFireStorage) { }

  ngOnInit(): void {
    this.getCmsData();
    this.getBookingData();
    this.typeSelected('Wedding');
  }
  getCmsData() {
    this.dataService.getCmsData().subscribe((res: any) => {
      console.log("response", res);
      this.cmsContent(res);
    })
  }
  cmsContent(cmsData: any) {
    this.cmsDataArr = cmsData
    this.btnHeadings = this.cmsDataArr.english
  }
  getBookingData() {
    this.firebaseService.getPhotoUsers().subscribe((res: any) => {
      console.log("formData", res);
      this.callData = res.map((e: any) => {
        return {
          id: e.payload.doc.id,
          fullName: e.payload.doc.data().bookingName,
          email: e.payload.doc.data().bookingEmail,
          locationofShoot: e.payload.doc.data().bookingCity,
          mobNo: e.payload.doc.data().bookingMob,
          address: e.payload.doc.data().bookingAdd,
          typeOfFunction: e.payload.doc.data().bookingFunction,
          functionDate: e.payload.doc.data().bookingDate,
          timeOfFunction: e.payload.doc.data().bookingTime,
          cameraQuantity: e.payload.doc.data().bookingCamera,
          droneQuantity: e.payload.doc.data().bookingDrones,
          checked: false
        }
      })
      console.log(this.callData)

    })
  }

  typeSelected(type: string) {


    this.headingFrame = type
    this.getImages();
  }
  langSelected(value: string) {
    this.langChoose = true;
    value === 'english' ?
      this.btnHeadings = this.cmsDataArr.english :
      value === 'hindi' ?
        this.btnHeadings = this.cmsDataArr.hindi : [];
    this.dataService.cmsDatafwrd = this.btnHeadings;
    console.log("selected", this.btnHeadings);

  }
  selectedUser(event, i) {
    event.target.checked ?
      this.callData[i].checked = true :
      this.callData[i].checked = false;
  }
  allSelected(event) {
    event.target.checked ?
      this.callData.forEach(element => {
        element.checked = true;
      }) :
      this.callData.forEach(element => {
        element.checked = false;
      });
    event.target.checked ?
      this.allChecked = true :
      this.allChecked = false;
  }

  exportExcel() {
    const exportArr = [];
    this.header = ['Full Name', 'Email', 'City', 'Mobile', 'Address', 'Function', 'Date of Function', 'Time of Function', 'Camera', 'Drone']
    this.callData.forEach(element => {
      if (element.checked) {
        exportArr.push({

          fullName: element.fullName,
          email: element.email,
          locationofShoot: element.locationofShoot,
          mobNo: element.mobNo,
          address: element.address,
          typeOfFunction: element.typeOfFunction,
          functionDate: element.functionDate,
          timeOfFunction: element.timeOfFunction,
          cameraQuantity: element.cameraQuantity,
          droneQuantity: element.droneQuantity,

        })
      }
    });
    console.log("respinseexcel", exportArr);

    exportArr.length ?
      this.exportService.generateExcel(exportArr, this.header, this.reportTitle, this.reportName) : '';
  }

  upload() {
    this.uploadFlag = true;
  }
  details() {
    this.uploadFlag = false;
  }
  onFileSelected(event) {
    this.fileSelectedFlag = true;
    this.fileSelected = event.target.files[0];
  }
  finalUpload() {
    var n = Date.now();
    const file = this.fileSelected;
    this.fileSelected = undefined;

    this.selectedFiles = undefined;

    this.currentFileUpload = new FileUpload(file);
    this.dataService.pushFileToStorage(this.currentFileUpload, this.headingFrame).subscribe(
      percentage => {
        this.percentage = Math.round(percentage);
        this.fileSelectedFlag = false;
        // this.getImages();
      },
      error => {
        console.log(error);
      }
    );



  }
  getImages() {
    this.dataService.getFiles(6, this.headingFrame).snapshotChanges().pipe(
      map(changes =>
        // store the key
        changes.map(c => ({
          key: c.payload.key,
          ...c.payload.val()
        }))
      )
    ).subscribe(fileUploads => {
      this.fileUploads = fileUploads;
    });
  }

  deleteBtn(value) {
    this.dataService.deleteFile(value, this.headingFrame);
  }

}

