<div class="termsBox mt-3">
    <div class="text-center">
        <b class="termsHeading">Terms and Conditions</b>
    </div>
    <!-- <pre> -->
    <div class="mx-3 mt-4 mb-0 text-center">
        <b class="welcomeHead">
            Welcome to <span style="color: orangered;font-family:'Kaushan Script', cursive;">Chandni Visual's </span> !
        </b>
    </div>
    <div class="mainTerms">
        <!-- <pre> -->
            
<p> You are reading Terms of Use ("Terms"), which is the agreement between you and Chandni Visual's  when you are using Chandni Visual's  online website or mobile software / application.</p>

 

<p> Through online website www.chandnivisuals.com(hereinafter referred to as "Website") and / or mobile software / application (hereinafter referred to as "App"), Chandni Visual's  shall provide you information, images, blogs, membership plans, workshops etc., which is and will remain the exclusive property of Chandni Visual's .</p>

 

<p> By accessing or using website through or in connection with the service ("Service"), you signify that you have read, understood, and agree to be bound by this Terms, Chandni Visual's  Privacy Policy and other applicable terms posted on www.chandnivisuals.com or provided with the Service, whether or not you are a registered User of our Service.</p>


<p> Chandni Visual's  reserves the right to amend these Terms at any time in our sole discretion, by notifying you of the change in writing or electronically (including without limitation, by e-mail or by posting a notice on online website that the terms have been updated). </p>
 

<p> The changes also will appear in this document, which you can access at any time by going to the Terms of Use Section. Your continued use of the Service after any such changes constitutes your acceptance of the revised Terms. These Terms applies to all visitors, users, and others who access the Service ("Users").</p>

 

 <h3> (1)            Eligibility for using Chandni Visual's </h3>

 

 <p> To access or use Chandni Visual's , you should be at least 18 years of age or, if older, the age of majority in your jurisdiction, otherwise you shall not use Chandni Visual's . Chandni Visual's  is for your personal, non-commercial as well as commercial use as soon as you enter into a separate agreement with us for your commercial use or through the acceptance of terms & conditions. You shall not use Chandni Visual's  if we have terminated your account or banned you.</p>

 

<h3> (2)            Use of the Services </h3>


<p> Chandni Visual's  is an intermediary and offers an online social networking platform through its Website (www.chandnivisuals.com) and mobile software / application. The online social networking platform enables people with photography interests to interact, learn and enhance their photography interests, skills. The online social networking platform is designed in a manner wherein the users can share and showcase their photography skills with other photography enthusiasts, and get reviews, tips, guidelines from photography professionals.</p>

 

<p> The online social networking platform also enlists various photography events, workshops, photo walks, photo tours providing a learning opportunity for all photography enthusiasts.</p>


<p> It is not mandatory to complete the registration process in order to visit the online website.</p>

 

<p> To access certain features of the Service, including applying to photography jobs (projects), uploading photos, posting questions about the photography, places, commenting on other user's work, you will need to register with Chandni Visual's  and create an account, by providing true and correct credentials, including valid e-mail address and valid mobile number. You will then be able to access to the services and functionality that we may establish and maintain from time to time, in our sole discretion. Such e-mail address and mobile number must be one where we can reach you via voice or sms with relevant services offerings. In the event we cannot correspond with you through the given e-mail address or mobile number, your User Content may be rejected and your account may be disabled. When you set up an individual user account on Chandni Visual's  along with the password, we create a member profile called as My Account for you that will include personal information you provide. You are entirely responsible for maintaining the confidentiality of your password and My Account. You agree to notify us immediately if you suspect any unauthorized use of My Account or access to your password. You are solely responsible for any and all use of your account. Passwords are subject to cancellation or suspension by Chandni Visual's  at any time.</p>

 

<p> You may also be able to register an account and subsequently access My Account through a social networking site, such as Facebook or Google+ ("Social Networking Website"). If you access Chandni Visual's  through a Social Networking Website you agree that we may access, make available through Chandni Visual's , and store (if applicable) any information, data, text and/or other materials that you have provided to and stored and made accessible in your Social Networking Website account so that it is available on and through Chandni Visual's  via your account and your profile page. Subject to the privacy settings that you have set with the Social Networking Website account you use to access Chandni Visual's , personally identifiable information that you post to that Social Networking Website may be displayed on My Account. You acknowledge that your relationship with Social Networking Website is governed solely by your agreement with those Social Networking Website and we disclaim any liability for personally identifiable information that may be provided to us by a Social Networking Site in violation of the privacy settings that you have set with that Social Networking Website account.</p>


<p> Subject to your compliance of these Terms, we grant you a limited, non-exclusive, non-transferable, non-sub-licensable license to access and make personal and non-commercial use of the Service. This license does not include any resale or commercial use of any part of the Service, or its contents; any  information and use of any blogs, images or other material included in the Service; any derivative use of any part of the Service or its contents; any downloading, copying, or other use of account information for the benefit of any third party; or any use of data mining, robots, or similar data gathering and extraction tools.</p>

 

<p> All rights not expressly granted to you in these Terms are reserved and retained by us or our licensors, suppliers, publishers, rights-holders, or other content providers. No part of the Service may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without our express written consent. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Chandni Visual's without our express written consent.</p>

 

<p> Needless to say, the licenses granted by us automatically terminates if you do not comply with these Terms.</p>


<p> In order to protect our Members from unsolicited advertising or solicitation, Chandni Visual's reserves the right to restrict the number of communications that a Member may send to other Members in any 24-hour period to a number that Chandni Visual's deems appropriate in its sole discretion.</p>

 

<p> There may be scheduled as well unscheduled service interruptions and you acknowledge that Chandni Visual's will not be liable for any interruption of the Service, delay or failure to perform.</p>

<p> You are solely responsible for your interactions with other Chandni Visual's Users. We have the right, but not an obligation, to monitor disputes between you and other Users. Chandni Visual's shall have no liability for your interactions with other Users, or for any User’s action or inaction.</p>

 

<b> Position & Assignment of Vendor’s work </b>

 

<p> In order to work for Chandni Visual's , where the company hires independent photographer providing freelancing services the Vendor agrees to this terms and conditions as mentioned herein. The Vendor will be notified of the job assignment and / or contract work on a case-by-case basis only. Chandni Visual's is under no obligation to guarantee Vendor any minimum number of contracts or any minimum number of hours. All work performed by the Vendor for Chandni Visual's at all times shall be governed by the covenants of this Terms and Conditions.</p>

 

<p> Vendor with their complete profile, if approved by Chandni Visual's , can apply to the projects listed on website. Shortlisting of photographers for each listed project is on the sole discretion of clients listing their requirements with Chandni Visual's  .  </p>

 

<p> Vendor will provide the services that Client orders and Vendor accepts through the Event Hollow services (“Services”). Each category of Services (for example, photography or floral arrangements) may be subjected to additional terms specific to that Service. Those terms are provided in a schedule to this Agreement, which is incorporated herein by reference (each a “Schedule”). If Client orders such a category of Services and Vendor agrees to provide that category of Services, then the terms of the applicable Schedule will apply.</p>

 

<p>The Vendor and Chandni Visual's hereby specifically agree that Vendor is performing the services as an independent Vendor and is not an employee of Chandni Visual's . Nothing in this Terms & Conditions shall be construed as creating an employer-employee relationship, as a guarantee of future employment or engagement, or as a limitation upon Chandni Visual's  or the Vendor’s discretion to terminate the independent Vendor relationship at any time without cause. Vendor further agrees to be responsible for all of Vendor’s federal and provincial taxes, withholding, Social Security, insurance, and all other benefits.</p>

 

<h3> (3)            Deliverables & Payments when Vendor is working for Chandni Visual's for its client services </h3>

 

<p> In exchange for successful completion of assignments the Vendor is eligible to receive financial compensation. The amount of compensation will depend upon the level of work performed and will be at an agreed upon rate prior to the event. In the event the Vendor works beyond the contracted time of the assignment; the company is not responsible for additional compensation to the Vendor unless the client has agreed upon extending the assignment with Chandni Visual's . The amount of additional compensation due to the extension of the assignment will depend upon the level of work performed and will be at an agreed upon rate prior to the event. All images must follow guidelines as per expectation document shared by the client.</p>

<p> Vendor needs to submit all original images and final deliverables as per expectation document within 24 hours and 72 hours respectively of the shoot. On successful submission and approval of images from client while meeting client expectations, payment for the project shall be processed. Payment shall be made within 7-10 business days from submission of bill from Vendor. Payment for any extra work done in agreement with the client shall be payable to the Vendor only after receiving the amount from client. Chandni Visual's shall be responsible to pay assigned amount only within 7-10 business days.</p>

 

<p> The Vendor agrees to follow the timing schedule to finish the assignments. If the Vendor is photographing or filming events, original files must be delivered to Chandni Visual's by the end of the event being photographed/filmed. If Vendor fails to complete the assignment in predecided time, Chandni Visual's is not liable to pay for additional hours spent in the project.</p>

 

<p> Any other expenses that are to be incurred during shoot / other related activities during the tenure of theagreement shall be borne by the Vendor and are not included in the cost per photo shoot shared here.</p>

 

<h3> (4)            Tax, If Applicable</h3>

 

<p> TDS of 10% shall be applicable on all payments of more than INR 30,000 to the Vendor. TDS shall not be deducted for payments less than the stated amount until it reaches the stated amount within current financial year. TDS shall be applicable on all payments under current financial year. If TDS is not deducted earlier on the payments made, accumulated TDS shall be deducted in next payment if total payment to the Vendor is more than INR 30,000. PAN Card details and a copy must be submitted for the same.</p>

 

<h3> (5)            User Content </h3>


<p> Some areas of the Service may allow Users to upload, publish, display, link to or otherwise make available (hereinafter, "post") comments, questions, highlights, and other information including Users’ names, voices and likenesses ("User Content"). You are solely responsible for your User Content.</p>

 

<p> You agree not to post User Content that is illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights (including publicity rights), or otherwise injurious to third parties or objectionable, and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam" or unsolicited commercial electronic messages. You shall not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of content. You hereby represent that you are the owner of all the copyright rights with respect to, or that you have the legal right to post, your User Content, and that you have the power to grant the license granted below. Chandni Visual's reserves the right to monitor, reject and/or remove any User Content at any time. For example, Chandni Visual's may, but is not obligated to, reject and/or remove any User Content that Chandni Visual's believes, in its sole discretion, violates these provisions.</p>


<p> Chandni Visual's takes no responsibility and assumes no liability for any User Content that you or any other Users or third parties post or send over the Service. You understand and agree that any loss or damage of any kind that occurs as a result of the use of any User Content that you post is solely your responsibility. Chandni Visual's is not responsible for any public display or misuse of your User Content. You understand and acknowledge that you may be exposed to User Content that is inaccurate, offensive, indecent, or objectionable, and you agree that Chandni Visual's shall not be liable for any damages you allege to incur as a result of such User Content. Chandni Visual's may provide tools for you to remove some User Content, but does not guarantee that all or any User Content will be removable.</p>

 

<h3> (6)            License Grant</h3>


<p> By posting any User Content on the Service, you expressly grant to Chandni Visual's a nonexclusive, royalty-free, perpetual, irrevocable, and fully sub-licensable right to use, reproduce, modify, adapt, publish, perform, translate, create derivative works from, distribute, and display such content throughout the world in any media. You represent and warrant that you own or otherwise control all of the rights to the content that you post; that the content is accurate; that use of the content you supply does not violate this policy and will not cause injury to any person or entity; and that you will indemnify Chandni Visual's for all claims resulting from content you supply.</p>

 

<h3> (7)            Our Proprietary Rights</h3>


<p> Except for your User Content, the Service and all materials therein or transferred thereby, including, without limitation, software, images, text, graphics, illustrations, logos, patents, trademarks, service marks, copyrights, photographs, audio, videos, music, and User Content (the "Chandni Visual's Content"), and all intellectual property Rights related thereto, are the exclusive property of Chandni Visual's and its licensors. Except as explicitly provided herein, nothing in these Terms shall be deemed to create a license in or under any such intellectual property Rights, and you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works from any materials or content accessible on the Service. Use of Chandni Visual's Content or materials on the Service for any purpose not expressly permitted by these Terms is strictly prohibited.</p>


<p> You may choose to or we may invite you to submit comments or ideas about the Service, including without limitation about how to improve the Service or our products ("Ideas"). By submitting any Idea, you agree that your disclosure is gratuitous, unsolicited and without restriction and will not place Chandni Visual's under any fiduciary or other obligation, and that we are free to use the Idea without any additional compensation to you, and/or to disclose the Idea on a non-confidential basis or otherwise to anyone. You further acknowledge that, by acceptance of your submission, Chandni Visual's does not waive any rights to use similar or related ideas previously known to Chandni Visual's , or developed by its employees, or obtained from sources other than you.</p>

 

<h3> (8)            Paid Services</h3>


<p> Billing Policies. Certain aspects of the Service may be provided for a fee or other charge. These fees and charges, if any, are described on Chandni Visual's . If you elect to use paid aspects of the Service, you agree to the pricing, payment and billing policies applicable to such fees and charges, posted on Chandni Visual's . Chandni Visual's may add new services for additional fees and charges, or amend fees and charges for existing services, at any time in its sole discretion.</p>


<p> No Refunds. You may cancel your Chandni Visual's Account at any time; however, there are no refunds for cancellation. In the event that Chandni Visual's suspends or terminates your Chandni Visual's Account or these Terms, you understand and agree that you shall receive no refund or exchange for any unused time on a subscription, any license or subscription fees for any portion of the Service, any content or data associated with your Chandni Visual's Account, or for anything else. You acknowledge that Chandni Visual's has the perpetual and irrevocable right to delete any or all of your content and data from Chandni Visual's 's servers and from the Service, whether intentionally or unintentionally, and for any reason or no reason, without any liability of any kind to you or any other party.</p>

 

<h3> (9)            Copyright Complaints</h3>

 

<p> If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement and is accessible via the Service, please send us the following information: </p>
<div class="subPoints">
<b> (a)            An electronic or physical signature of a person authorized to act on behalf of the copyright owner:</b>

<b> (b)            A description of the copyrighted work that you claim has been infringed upon;</b>

<b> (c)            A description of the material that is claimed to be infringing and where it is located on the Service;</b>

<b> (d)            Your complete contact details including contact number and e-mail address;</b>

<b> (e)            A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or law; and</b>

<b> (f)            A statement, made under penalty of perjury, that the above information is accurate, and that you are the copyright owner or are authorized to act on behalf of the owner.</b>
</div>

<p> Chandni Visual's  Copyright Attorney / Agent for notice of claims of copyright infringement can be reached as follows:</p>

<div class="subPoints">
<b> Copyright Attorney / Agent:</b>

<b> Attn: Advocate Name</b>

<b> Address: advocate adress</b>

<b> E-mail address:advocate email id</b>
</div>

<p> Please note that this procedure is exclusively for notifying Chandni Visual's and its affiliates that your copyrighted material has been infringed.</p>

 

<p> In order of the work of the Vendor, the Vendor acknowledges that any photographs, video or works made pursuant to this agreement are works made for hire. As such, the copyrights to the images or video produced by him/her belong to Chandni Visual's . The Vendor hereby assigns the copyright to all works made pursuant to this Agreement to Chandni Visual's . The Vendor also agrees to execute any additional documents necessary to assign any copyright to Chandni Visual's . The Vendor agrees not to sell any image or video taken during a contracted event from Chandni Visual's to any person, company, group, advertising agency or otherwise without Chandni Visual's ’s express written permission.</p>

 

<p> Chandni Visual's grants the Vendor permission to use the images taken during a contracted individual or family event for the Vendor’s personal print portfolio only. Digital usage of images or video taken during the contracted event is strictly prohibited. Digital usage includes but is not limited to websites, online portfolios, social media sites, and photo sharing sites. Chandni Visual's does not grant the Vendor permission to use the images taken during any corporate event or services for the Vendor’s personal portfolio.</p>

 

<p> The company retains all copyrights to the images and video and may request that the Vendor discontinue use of the images or video at any time for any reason.</p>

 

<h3> (10)         Third-Party Websites, Advertisers or Services</h3>


<p> Chandni Visual's may contain links to third-party websites, advertisers, or services that are not owned or controlled by Chandni Visual's . Chandni Visual's has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. If you access a third-party website from Chandni Visual's , you do so at your own risk, and you understand that these Terms and Chandni Visual's ’ Privacy Policy do not apply to your use of such sites. You expressly relieve Chandni Visual's from any and all liability arising from your use of any third-party website or services or third party owned content.</p>


<p> Additionally, your dealings with or participation in promotions of advertisers found on Chandni Visual's , including payment and delivery of goods, and any other terms (such as warranties) are solely between you and such advertisers. You agree that Chandni Visual's shall not be responsible for any loss or damage of any sort relating to your dealings with such advertisers.</p>


<p> We encourage you to be aware of when you leave the Service, and to read the terms and conditions and privacy policy of any third-party website or service that you visit.</p>

 

<h3> (11)        Exclusivity</h3>

 

<p> The Vendor understands that he/she represents Chandni Visual's and not any other business while completing assignments for Chandni Visual's . While directly working for Chandni Visual's , Vendor will not advertise his/her own business, will not solicit work for him/herself, and will only distribute Chandni Visual's  materials, business cards, and name. The Vendor is not bound by an exclusivity contract when not working directly for Chandni Visual's .</p>

 

<h3> (12)         Confidentiality</h3>

 

<p> The Vendor understands that all materials, price lists, contracts, financial documents, agreements, and other information or documents that are given to him/ her in the scope of his/her assignments are the exclusive property of Chandni Visual's and are privileged and confidential information. The Vendor agrees not to reveal this information to anyone, nor to use this information to the detriment of Chandni Visual's , including to circumvent or to undercut sales. The Vendor agrees that all materials as described above must be returned to Chandni Visual's at such time as this or any subsequent contract or agreement ends.</p>

 

<h3> (13)         Privacy</h3>

 

<p> We view protection of your privacy as a very important principle. We shall store and process your personal information on computers that are protected by physical as well as reasonable technological security measures and procedures in accordance with Information Technology Act 2000 and rules there under. You are advised to access and review our present Privacy Policy. If you object to your personal information being transferred or used in this way, please do not use Chandni Visual's .</p>

 

<p> Chandni Visual's and its affiliates may share / sell / transfer / license / covey some or all of your personal information with another business entity should Chandni Visual's (or its assets) plan to merge with or are acquired by that business entity, or re-organization, amalgamation, restructuring of business or for any other reason whatsoever. Should such a transaction or situation occur, the other business entity or the new combined entity will be required to follow the privacy policy with respect to your personal information. Once you provide your information to Chandni Visual's , you provide such information to Chandni Visual's and its affiliate and Chandni Visual's and its affiliate may use such information to provide you various services with respect to your transaction whether such transaction is conducted on Chandni Visual's or with third party merchant's or third-party merchant's website.</p>

 

<h3> (14)         Termination</h3>

 

<p> Chandni Visual's may terminate, suspend, or otherwise refuse to permit your access to the Service without notice and liability for any reason, with or without notice, which may result in the forfeiture and destruction of all information associated with your Account. If you wish to terminate your account, you may do so by following the instructions on the Site / App. Any fees paid hereunder are non-refundable. All provisions of these Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

 

<p> It is at Chandni Visual's  or the Vendor’s discretion to terminate the independent Vendor relationship at any time without cause.</p>

 

<h3> (15)         Indemnity</h3>

 

<p> You shall defend, indemnify, and hold harmless Chandni Visual's , its affiliates and each of its and its affiliates' employees, contractors, directors, suppliers and representatives from all losses, costs, actions, claims, damages, expenses (including reasonable attorney's fees) or liabilities, that arise from or relate to your use or misuse of, or access to, the Website, App, Service, Content or otherwise from your User Submissions, violation of these Terms, or infringement by you, or any third party using your Account, of any intellectual property or other right of any person or entity (save to the extent that a court of competent jurisdiction holds that such claim arose due to an act or omission of Chandni Visual's ). Chandni Visual's reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with Chandni Visual's in asserting any available defenses.</p>

 

<p> The Vendor agrees to indemnify and hold harmless Chandni Visual's  affiliates, respective officers, directors, agents, employees, and Vendors from any and all claims, demands losses, causes of action, damage, lawsuits, judgements, including attorney's fees and costs.</p>

 

<h3> (16)         Disclaimers of Warranties and Limitation of Liability</h3>

 

<p> Vendor's Liability: The Vendor warrants that he / she will perform when booked for a photographic assignment, and that he/she is liable for any loss or other financial liability suffered by Chandni Visual's due to failure to perform other than a documented medical emergency or an act of nature. Chandni Visual's is not liable for any loss or damage to the Vendor’s equipment under this contract.</p>

 

<p> The Vendor understands he/she takes responsibility to ensure his/her own safety. Chandni Visual's  will not be held responsible for any injury, loss, or damage of any kind whatsoever suffered or incurred by the Vendor.</p>

 

<p> The Vendor agrees to not inaccurately depict his or her roles and responsibilities for Chandni Visual's . Inaccurate depiction of roles and responsibilities includes but is not limited to:</p>

 
<div class="subPoints">
<b> a)              claiming lead shooting responsibilities when Vendor did not shoot as a lead photographer,</b>

<b> b)             claiming second shooting responsibilities when Vendor did not shoot as a second photographer,</b>

<b> c)              claiming third shooting responsibilities when Vendor did not shoot as the third photographer,</b>

<b> d)             claiming a specific number of jobs were shot by the Vendor that are inaccurate.</b>
</div>

<p> The disclaimers and limitations on liability in this section apply to the maximum extent allowable under applicable law. Nothing in this section is intended to limit any rights you have which may not be lawfully limited.</p>

 

<P> You are solely responsible for your interactions with other Chandni Visual's Users or other parties and we are not responsible for the activities, omissions, or other conduct, whether online or offline, of any third parties, including other Chandni Visual's Users. We are not responsible for any incorrect, inaccurate, or unlawful content (including any information in My Profile) posted on Chandni Visual's , whether caused by Users or by any of the equipment or programming associated with or utilized in Chandni Visual's . We assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, any communication with any third parties, including other Chandni Visual's Users. We are not responsible for any problems or technical malfunction of any hardware and software due to technical problems on the Internet or on Chandni Visual's or combination thereof, including any injury or damage to any Chandni Visual's Users or to any person's computer related to or resulting from participation or downloading materials in connection with Chandni Visual's .</P>

 

<p> Under no circumstances is Chandni Visual's responsible for any loss or damage resulting from the use of Chandni Visual's or from any content posted on Chandni Visual's or transmitted to Chandni Visual's Users, or any interactions between Chandni Visual's Users, whether online or offline.</p>

 

<p> Chandni Visual's is provided "as-is" and as available. We expressly disclaim any warranties and conditions of any kind, whether express or implied, including the warranties or conditions of merchantability, fitness for a particular purpose, title, quiet enjoyment, accuracy, or non-infringement. We make no warranty that: (1) Chandni Visual's will meet your requirements; (2) Chandni Visual's will be available on an uninterrupted, timely, secure, or error-free basis; or (3) the results that may be obtained from the use of Chandni Visual's will be accurate or reliable.</p>

 

<p> You hereby release Chandni Visual's and its affiliates, directors, representatives, etc. from any and all claims, demands, and losses, damages, rights, claims, and actions of any kind that are either directly or indirectly related to or arises from: (1) the actions, content, or data of third parties (including, advertisers and other Users) (2) your participation in any offline events.</p>

 

<p> In no event shall Chandni Visual's be liable to you or any third party for any lost profit or any indirect, consequential, exemplary, incidental, special, or punitive damages arising from your use of Chandni Visual's , even if we have been advised of the possibility of such damages. Notwithstanding anything to the contrary contained herein, where permitted by applicable law, you agree that Chandni Visual's liability to you for any damages arising from or related to your use of Chandni Visual's (for any cause whatsoever and regardless of the form of the action), will at all times be limited to INR 1,000 (Rupees one thousand only).</p>

 

<h3> (17)         Equipment</h3>

 

<p> The Vendor is responsible for providing all of his/her own equipment with which to complete the assignments. Chandni Visual's requires that events be photographed in digital RAW file format and that all original digital RAW files are relinquished to Chandni Visual's upon completion of the contracted job. The Vendor is responsible for maintaining a digital copy of their images or video until the RAW files are delivered to Chandni Visual's .</p>

 

<h3> (18)        Image/Video Memory Cards</h3>

 

<p> Upon completion of the contracted shoot/assignment, the Vendor will turn in memory cards with all photo and/or video taken for the duration of the shoot. Memory cards should be handed to an Chandni Visual's representative, typically a Lead or second shooter. Memory cards will be returned to the Vendor once images/video is backed-up to Chandni Visual's servers.</p>

 

<h3> (19)         Availability of the Vendor</h3>

 

<p> Cancellations of scheduled events by Vendor may occur with prior written notice and acceptance from the company subject to the following terms to the reasonable extent that circumstances permit:</p>

 
<div class="subPoints">
<b> a)              At least 15 days’ prior written notice;</b>

<b> b)             No Vendor relationship (no prior shoot or engagement) with the Client;</b>

<b> c)              Vendor has emergency or other personal conflict with the schedule (family emergency, pregnancy duedate, etc.); and Vendor provides assistance in finding replacement photographer of similar skills, experience and training for replacement.</b>
</div>
 

<h3> (20)         Assignment </h3>

 

<p> This Agreement, and any rights and licenses granted hereunder, shall not be transferred or assigned by you, but may be assigned by Chandni Visual's , without restriction.</p>

 

<h3> (21)         No Waiver </h3>

 

<p> No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and Chandni Visual's  failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.</p>

 

<h3> (22)         Notices</h3>

 

<p> All notices and communications shall be in writing, in English and shall deemed given (a) if delivered personally or by commercial messenger or courier service or mailed by registered or certified post (return receipt requested) or (b) sent via e-mail communication (with acknowledgment of complete transmission) to the following address:</p>


<b> If to Chandni Visual's , by e-mail at laweremailId or at the address:</b>

 

lawer ka address --------------------

 

<p> If to a registered User, by e-mail at the address specified while registering or address available on My Account.</p>

 

<p> Notice shall be deemed to have been served 48 hours after it has been sent, dispatched, displayed, as the case may be, unless, where notice has been sent by e-mail, it comes to the knowledge of the sending party, that the e-mail address is invalid.</p>

 

<h3> (23)         Applicable Law</h3>

 

<p> Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India without reference to conflict of laws principles. The place of jurisdiction for disputes arising in relation hereto shall be subject to the exclusive jurisdictions of the courts in New Delhi, India.</p>


<b> Contact Us: Please contact us for any questions or comments regarding Chandni Visual's .</b>

 

<div> Contact Number: +91 9250925044/+91 9250925088</div>

<div> E-mail address: thegreatjaats@gmail.com</div>
        <!-- </pre> -->
        <!-- </pre> -->
    </div>