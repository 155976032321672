import { Component, HostListener, OnChanges, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { cvIcons } from 'src/app/models/cv-icons';
import { DataInteractionService } from 'src/app/services/data-interaction.service';
import { threadId } from 'worker_threads';


@Component({
  selector: 'app-home-component',
  templateUrl: './home-component.component.html',
  styleUrls: ['./home-component.component.scss']
})
export class HomeComponentComponent implements OnInit, OnChanges {
  @Output()
  icons;
  screenWidth: number;
  weddingHead: string = 'Wedding';
  wildHead: string = 'Wild';
  mobFlag: boolean;
  bookingClicked: boolean;
  langChoose: boolean;
  cmsDataArr: any;
  textToShow: any;
  aboutFlag: boolean;
  weddingFlag: boolean;
  personalFlag: boolean;
  wildFlag: boolean;
  eventFlag: boolean;
  bookshootFlag: boolean;
  locateFlag: boolean;
  termsFlag: boolean;
  faqFlag: boolean;
  toggleClicked: boolean;
  typeofImages: string;
  subscriptions = new Subscription;
  permanentFlag: boolean;
  cardImagesWedFlag: boolean;
  loaderFlag: boolean;
  cardImagesPerFlag: boolean;
  cardImagesWildFlag: boolean;
  cardImagesEventFlag: boolean;
  videoFlag: boolean;
  cardImagesAboutFlag: boolean;
  cardImagesCrouselFlag: boolean;
  scrollPage: boolean;
  cardNav1: boolean;
  cardNav2: boolean;
  cardNav3: boolean;
  cardNav4: boolean;
  clear: NodeJS.Timeout;
  constructor(private dataService: DataInteractionService) {
  }

  ngOnChanges() {

  }

  ngOnInit(): void {
    // this.animateLogo();
    this.getCmsData();
    this.getWeddingImages();
    this.getPortfolios();
    this.getWildNature();
    this.getEvents();
    // this.aboutImages();
    this.getCrouselImages();
    this.subscriptions.add(
      this.dataService.getHomeScreen().subscribe((res: any) => {
        this.navBtn(res)
      })
    );
    this.cardChange(1);

    this.screenWidth = screen.availWidth;
    this.icons = cvIcons.getImage();
    this.responsiveness(this.screenWidth);
    this.navBtn('about');
  }
  
  @HostListener('contextmenu', ['$event']) onRightClick(event) {
    event.preventDefault();
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 1) {
      this.scrollPage =true;
     }
     else{
       this.scrollPage = false;
     }
    }
    


    cardChange(num:number){
      switch (num){
        case  1:
          this.cardNav1=true;
          this.cardNav2=false;
          this.cardNav3=false;
          this.cardNav4=false;
          break;
          case 2:
            this.cardNav1=false;
          this.cardNav2=true;
          this.cardNav3=false;
          this.cardNav4=false;
          break;
          case 3 :
            this.cardNav1=false;
          this.cardNav2=false;
          this.cardNav3=true;
          this.cardNav4=false;
          break;
          case 4:
            this.cardNav1=false;
          this.cardNav2=false;
          this.cardNav3=false;
          this.cardNav4=true;
          break;
      }
    }
  getCmsData() {
    this.dataService.getCmsData().subscribe((res: any) => {
      console.log("response", res);
      this.cmsContent(res);
    })
  }

  cmsContent(cmsData: any) {
    this.cmsDataArr = cmsData;
    this.dataService.cmsDataArr = this.textToShow = this.cmsDataArr.english;
    this.permanentFlag = true;
  }
  responsiveness(screenWidth: number) {
    screenWidth < 1025 ?
      this.mobFlag = true :
      this.mobFlag = false;
     
      
      
  }

  // onRightClick(event) {
  //   event.preventDefault();
  // }
  langSelected(value: string) {
    this.langChoose = true;
    value === 'english' ?
      this.textToShow = this.cmsDataArr.english :
      value === 'hindi' ?
        this.textToShow = this.cmsDataArr.hindi : [];
    this.dataService.cmsDatafwrd = this.textToShow;
    console.log("selected", this.textToShow);

  }
  navBtn(value: string) {
    this.mobFlag ?
      this.toggleClicked = false :
      this.toggleClicked = true;
    switch (value) {
      case 'about':
        this.aboutFlag = true;
        this.weddingFlag = false;
        this.termsFlag = false;
        this.videoFlag = false;
        this.faqFlag=false;
        this.personalFlag = false;
        this.wildFlag = false;
        this.bookingClicked = false;
        this.eventFlag = false;
        this.bookshootFlag = false;
        this.locateFlag = false;
        break;
      case 'Wedding':
        this.aboutFlag = false;
        this.weddingFlag = true;
        this.personalFlag = false;
        this.wildFlag = false;
        this.videoFlag = false;
        this.termsFlag = false;
        this.typeofImages = value;
        this.faqFlag=false;
        this.eventFlag = false;
        this.bookshootFlag = false;
        this.locateFlag = false;
        break;
      case 'Personal':
        this.aboutFlag = false;
        this.weddingFlag = false;
        this.personalFlag = true;
        this.wildFlag = false;
        this.videoFlag = false;
        this.typeofImages = value;
        this.eventFlag = false;
        this.faqFlag=false;
        this.termsFlag = false;
        this.bookshootFlag = false;
        this.locateFlag = false;
        break;
      case 'Wild':
        this.aboutFlag = false;
        this.weddingFlag = false;
        this.personalFlag = false;
        this.videoFlag = false;
        this.wildFlag = true;
        this.eventFlag = false;
        this.faqFlag=false;
        this.termsFlag = false;
        this.typeofImages = value;
        this.bookshootFlag = false;
        this.locateFlag = false;
        break;
      case 'Event':
        this.aboutFlag = false;
        this.weddingFlag = false;
        this.personalFlag = false;
        this.wildFlag = false;
        this.eventFlag = true;
        this.videoFlag = false;
        this.faqFlag=false;
        this.termsFlag = false;
        this.typeofImages = value;
        this.bookshootFlag = false;
        this.locateFlag = false;
        break;
      case 'Video':
        this.aboutFlag = false;
        this.weddingFlag = false;
        this.personalFlag = false;
        this.wildFlag = false;
        this.eventFlag = false;
        this.faqFlag=false;
        this.videoFlag = true;
        this.termsFlag = false;
        this.typeofImages = value;
        this.bookshootFlag = false;
        this.locateFlag = false;
        break;
      case 'bookshoot':
        this.aboutFlag = false;
        this.weddingFlag = false;
        this.personalFlag = false;
        this.wildFlag = false;
        this.faqFlag=false;
        this.videoFlag = false;
        this.termsFlag = false;
        this.eventFlag = false;
        this.bookshootFlag = true;
        this.locateFlag = false;
        break;
      case 'locate':
        this.aboutFlag = false;
        this.weddingFlag = false;
        this.personalFlag = false;
        this.wildFlag = false;
        this.termsFlag = false;
        this.faqFlag=false;
        this.eventFlag = false;
        this.videoFlag = false;
        this.bookshootFlag = false;
        this.locateFlag = true;
        break;
      case 'terms':
        this.aboutFlag = false;
        this.weddingFlag = false;
        this.personalFlag = false;
        this.wildFlag = false;
        this.termsFlag = true;
        this.eventFlag = false;
        this.faqFlag=false;
        this.videoFlag = false;
        this.bookshootFlag = false;
        this.locateFlag = false;
        break;
        case 'faq':
        this.aboutFlag = false;
        this.weddingFlag = false;
        this.personalFlag = false;
        this.wildFlag = false;
        this.termsFlag = false;
        this.eventFlag = false;
        this.faqFlag=true;
        this.videoFlag = false;
        this.bookshootFlag = false;
        this.locateFlag = false;
        break;
    }
  }
  getWeddingImages() {
    this.getImages('Wedding');
  }
  getPortfolios() {
    this.getImages('Personal');
  }
  getWildNature() {
    this.getImages('Wild');
  }
  getEvents() {
    this.getImages('Event');
  }
  aboutImages() {
    this.getImages('About');
  }
  getCrouselImages() {
    this.getImages('Crousel');
  }
  getImages(data) {
    this.dataService.getFiles(6, data).snapshotChanges().pipe(
      map(changes =>
        // store the key
        changes.map(c => ({
          key: c.payload.key,
          ...c.payload.val()
        }))
      )
    ).subscribe(fileUploads => {
      const Filedata = fileUploads;
      data === 'Wedding' ?
        this.dataService.imagesWedGallery = Filedata :
        data === 'Personal' ?
          this.dataService.imagesPerGallery = Filedata :
          data === 'Wild' ?
            this.dataService.imagesWildGallery = Filedata :
            data === 'Event' ?
              this.dataService.imagesEventGallery = Filedata :
              data === 'About' ?
                this.dataService.imagesAboutGallery  = Filedata:
                data === 'Crousel' ?
                  this.dataService.imagesCrouselGallery  = Filedata: '';
      this.imagesFlag(data);
      console.log("valuessssss", data, Filedata);

    });
  }
  imagesFlag(data: any) {
    data === 'Wedding' ?
      this.cardImagesWedFlag = true :
      data === 'Personal' ?
        this.cardImagesPerFlag = true :
        data === 'Wild' ?
          this.cardImagesWildFlag = true :
          data === 'Event' ?
            this.cardImagesEventFlag = true :
            data === 'About' ?
              this.cardImagesAboutFlag = true : 
              data === 'Crousel' ?
                this.cardImagesCrouselFlag = true: '';
console.log("flagggg",data,this.cardImagesAboutFlag,this.cardImagesCrouselFlag);

  }
  bookingBtn() {
    this.bookingClicked = true;
    this.bookshootFlag = true
    this.aboutFlag = false;
  }
}


