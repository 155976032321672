
<div class="text-center cvCarousel m-3" *ngIf="aboutWork">
    <app-carousel></app-carousel>
</div>
<div class="reviewBox">
    <app-reviews></app-reviews>
</div>

    <div class="p-3 pt-4 m-3 paraBackground borderRadius boxShadow">
        <p class="m-0 p-0 fontColorB">
            The moment that passes is never returned, but those moments can only be captured in photographs, so that
            whenever you want to remember the past, the memories are refreshed when you turn the album over. In such a
            situation, the importance of those photos are increased, which are very beautifully drawn.
            Such pictures are always alive.</p><p class="fontColorW"> They are not less than a skilled handgun. To be a better photographer, it is
            very important to be patient, sensitive and nature lover.
            Many photographers think that they can buy good cameras and take good pictures. But no matter how good the
            camera is, it cannot do anything if there is nothing in your mind and heart (ie you don't have creativity).
        </p>
    </div>
   
    