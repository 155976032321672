import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FileUpload } from '../models/file-upload';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { cvVariables} from '../models/cvVariables';
@Injectable({
  providedIn: 'root'
})
export class DataInteractionService {
  cvCmsData = '/assets/jsons/cvCms.json'
  reviewData = '/assets/jsons/reviewData.json'
  
  cvV: cvVariables;
  basePath:string='/uploads';
  cmsContent:any;
  cmsDatafwrd = new Subject();
  cmsDatafwrd$ = this.cmsDatafwrd.asObservable();
  
  
  clickforHome$ = new Subject();
  imagesWedGallery: { key: string; name: string; url: string; file: File; }[];
  imagesPerGallery: { key: string; name: string; url: string; file: File; }[];
  imagesWildGallery: { key: string; name: string; url: string; file: File; }[];
  imagesEventGallery: { key: string; name: string; url: string; file: File; }[];
  imagesCrouselGallery: { key: string; name: string; url: string; file: File; }[];
  imagesAboutGallery: { key: string; name: string; url: string; file: File; }[];
  cmsDataArr: any;
  constructor(private http:HttpClient,private db: AngularFireDatabase, private storage: AngularFireStorage) { 
    // this.basePath = this.cvV.basePath;
    // this.basePath = '/uploads'
    console.log("gallery",this.imagesEventGallery,this.imagesWedGallery,this.imagesWildGallery,this.imagesPerGallery);
    
  }
  getCmsData (){
    return this.http.get(this.cvCmsData)
  }
  getreviewData(){
    return this.http.get(this.reviewData);
  }
  getHomeScreen(){
    return this.clickforHome$.asObservable()
  }
  getwedding(){
    return this.clickforHome$.asObservable()
  }
  getEvent(){
    return this.clickforHome$.asObservable()
  }
  getWild(){
    return this.clickforHome$.asObservable()
  }
  getPersonal(){
    return this.clickforHome$.asObservable()
  }
  
  pushFileToStorage(fileUpload: FileUpload,type:string): Observable<number> {
    const filePath = `${this.basePath}/${type}/${fileUpload.file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe(downloadURL => {
          fileUpload.url = downloadURL;
          fileUpload.name = fileUpload.file.name;
          this.saveFileData(fileUpload,type);
        });
      })
    ).subscribe();

    return uploadTask.percentageChanges();
  }

  private saveFileData(fileUpload: FileUpload,type:string): void {
    this.db.list(this.basePath+'/'+type).push(fileUpload);
  }

  getFiles(numberItems,type:any): AngularFireList<FileUpload> {
    const params = this.basePath+'/'+type;
    return this.db.list(params, ref =>
      ref.limitToLast(numberItems));
  }

  deleteFile(fileUpload: FileUpload,type:string): void {
    this.deleteFileDatabase(fileUpload.key,type)
      .then(() => {
        this.deleteFileStorage(fileUpload.name,type);
      })
      .catch(error => console.log(error));
  }

  private deleteFileDatabase(key: string,type): Promise<void> {
    return this.db.list(this.basePath+'/'+type).remove(key);
  }

  private deleteFileStorage(name: string,type): void {
    const storageRef = this.storage.ref(this.basePath+'/'+type);
    storageRef.child(name).delete();
  }
}
